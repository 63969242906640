<footer class="footer">
    <section class="container-items">

        <div class="div-item">
            <h3 class="title-item">CONTACTEZ-NOUS</h3>
            <ul>
                <li>
                    <a href="mailto:info@venditravels.com">info@venditravels.com</a> - <a
                        href="tel:+33387885670">03.87.88.09.30</a>
                </li>
                <li>
                    du Lundi au Vendredi
                </li>
                <li>
                    de 9h00 à 12h30 / de 13h30 à 17h
                </li>
            </ul>
        </div>

        <div class="div-item">
            <h3 class="title-item">NOS MOYENS DE PAIEMENT</h3>
            <div class="container-icon-creditcard">
                <img src="assets/visa-icon.png">
                <img src="assets/mastercard-icon.png" style="border-radius: 17px;">
                <img src="assets/amex-icon.png">
                <img src="assets/ancv-icon.jpg">
            </div>
            <span class="span-rights">Tous droits réservés à VENDI Travels - FISKA\France - Fiska\Group</span>
        </div>

        <div class="div-item">
            <h3 class="title-item">INFOS</h3>
            <ul>
                <!--      <li>
                    <a>Qui sommes nous ?</a>
                </li> -->
                <li>
                    <a routerLink="/conditions-generales-de-vente">Conditions générales de vente</a>
                </li>
                <li>
                    <a routerLink="/mentions-legales">Mentions légales</a>
                </li>
            </ul>
        </div>

    </section>

</footer>