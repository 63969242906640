import { Component, OnInit } from '@angular/core';
import { VendiTravelsService } from 'src/app/services/venditravels.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private vt: VendiTravelsService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  changePage(value) {

    console.log('v : ', value)
    this.vt.resetSearch('type', value)
  }

  goHome() {
    this.router.navigate(['/'])
  }

  activeSideNavMenu() {
    this.vt.activeSideNavMenu()
  }


}
