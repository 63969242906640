import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, DAYS_OF_WEEK, CalendarView, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
const _ = require('lodash');

@Component({
  selector: 'mwl-demo-utils-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent {

  @Input() view: CalendarView;
  @Input() viewDate: Date;
  // @Input() events;
  @Input() firstDate: Date = new Date();
  @Input() lastDate: Date = new Date();
  @Input() locale: string = 'fr';
  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() viewDateChange = new EventEmitter<Date>();
  @Output() refreshdate: EventEmitter<any> = new EventEmitter<any>();

  // public today = new Date()
  public CalendarView = CalendarView;

  ngOnChanges(changes: SimpleChanges) {

    console.log('CHANGEMENTS CALENDAR : ', changes);

    // this.refreshDate();

    if (!_.isNil(_.get(changes, 'lastDate.currentValue'))) {
      this.lastDate = changes.lastDate.currentValue;
      console.log(this.lastDate);
    }

    if (!_.isNil(_.get(changes, 'firstDate.currentValue'))) {
      this.firstDate = changes.firstDate.currentValue;
      this.viewDate = this.firstDate;
      console.log(this.firstDate);
    }

    if (!_.isNil(_.get(changes, 'viewDate.currentValue'))) {
      this.viewDate = changes.viewDate.currentValue;
    }

    // if (!_.isNil(_.get(changes, 'events.currentValue', ))) {
    //   this.events = changes.events.currentValue;
    //   console.log(this.events)
    // }


  }

  maxDate() {

    // console.log(
    //   'Month : \n',
    //   '\t view: ', this.viewDate.getMonth(), '\n',
    //   '\t lastDate: ', this.lastDate.getMonth(), '\n',
    //   'Year : \n',
    //   '\t view: ', this.viewDate.getFullYear(), '\n',
    //   '\t lastDate: ', this.lastDate.getFullYear(), '\n',
    // )

    return this.viewDate.getFullYear() < this.lastDate.getFullYear() || this.viewDate.getMonth() < this.lastDate.getMonth() && this.viewDate.getFullYear() <= this.lastDate.getFullYear()

  }

  refreshDate() {
    this.refreshdate.emit(this.viewDate)
  }


}




/* @Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'HH:mm', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }
} */
