import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { VendiTravelsService } from 'src/app/services/venditravels.service';
import { EventBus } from 'src/app/services/utils/models/event-bus.model';
import { HelperService } from 'src/app/services/helper.service';
const _ = require('lodash');

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit, OnDestroy {
  @Input() data  // list, todisplay, topush, type, params

  public params;
  public ctrl = new FormControl();
  public completelist
  public filteredlist: Observable<Object[]>;
  public selectedlist = [];


  @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) auto: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: false }) inputTrigger: MatAutocompleteTrigger;
  @ViewChild('chipscontainer', { read: ElementRef, static: false }) public chipscontainer: ElementRef<any>;

  // private _resetSearch: EventBus<string> = new EventBus<string>();

  private _resetSearchSubscription: Subscription;

  constructor(
    private vt: VendiTravelsService,
    private hlp: HelperService,
  ) { }


  ngOnInit() {

    this._resetSearchSubscription = this.vt.subscribeResetSearch((s) => {
      // this.ngOnInit()

      this.completelist = _.concat(this.data.list, []);
      this.params = this.vt.params
      this.selectedlist = []
    });

    this.completelist = _.concat(this.data.list, []);
    this.params = this.vt.params

    this.selectedlist = this.completelist.filter(element => {

      const i = this.params[this.data.type].findIndex(p => p === element[this.data.topush])
      if (i !== -1) return element
    })

    this.filteredlist = this.ctrl.valueChanges.pipe(
      startWith(null),
      map((e) => e ? this._filter(e) : this.completelist.slice()));
  }

  addToParams(key, value) {
    this.params = this.vt.addToParams(key, value, true)
  }



  openPanel(event) {
    console.log('openPanel');
    /* 
        console.log(this.input.nativeElement.getAttribute('aria-expanded'));
        console.log(this.input.nativeElement.getAttribute('aria-owns'));
    
        this.input.nativeElement.setAttribute('aria-expanded', "true");
        this.input.nativeElement.setAttribute('aria-owns', "mat-autocomplete-1");
    
    
        console.log(this.input.nativeElement.getAttribute('aria-expanded'));
        console.log(this.input.nativeElement.getAttribute('aria-owns')); */

    // console.log(this.input.nativeElement.autocomplete);

    // console.log(this.input.nativeElement['aria-expanded']);

    /* 
    if (!this.input.nativeElement['aria-expanded']) {
      this.input.nativeElement['aria-expanded'] = true;
      this.input.nativeElement['aria-owns'] = "mat-autocomplete-1";
    }
 
    console.log(this.input.nativeElement['aria-expanded']);
 
 */


    this.inputTrigger.openPanel();


    // event.stopPropagation();
  }

  scrollDownList() {
    this.chipscontainer.nativeElement.scrollTop = this.chipscontainer.nativeElement.scrollHeight
  }

  remove(value) {
    const index = this.selectedlist.indexOf(value)

    this.vt.removeFromParams(this.data.type, this.selectedlist[index][this.data.topush])
    this.completelist.push(this.selectedlist[index])
    this.completelist.sort((a, b) => (a[this.data.todisplay] > b[this.data.todisplay]) ? 1 : -1)
    if (index >= 0) { this.selectedlist.splice(index, 1); }
  }

  selected(event: MatAutocompleteSelectedEvent) {

    if (this.selectedlist.length >= 9) {
      this.hlp.message('Limite de paramètre atteinte', 'error')
    } else {
      const index = this.completelist.findIndex((e) => e[this.data.todisplay] === event.option.viewValue)
      this.params = this.addToParams(this.data.type, this.completelist[index][this.data.topush])
      this.selectedlist.push(this.completelist[index])
      this.completelist.splice(index, 1)
      this.input.nativeElement.value = '';
      this.ctrl.setValue(null);
    }

  }

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.completelist.filter(e => e[this.data.todisplay].toLowerCase().indexOf(filterValue) !== -1);
  }

  ngOnDestroy() {
    this._resetSearchSubscription.unsubscribe();
  }
}
