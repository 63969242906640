import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
const _ = require('lodash');
export class SearchComponentComponent {
    constructor(vt, loadingService, router, hlp) {
        this.vt = vt;
        this.loadingService = loadingService;
        this.router = router;
        this.hlp = hlp;
        this.search = new EventEmitter();
        this.listeDestinations = [];
        this.airports = [];
        this.thematiques = [];
        this.today = new Date();
        this.lodash = _;
        this.searchActive = false;
        this.tmpRegime = '';
        this.params = [];
        this.regimes = [];
        this.options = {
            floor: 0,
            ceil: 5000,
            step: 100,
            minRange: 100,
            //showTicks: true,
            translate: (value) => {
                let tmp = (value < 1000 ? value : value.toString().slice(0, 1) + ' ' + value.toString().slice(-3)) + '€';
                if (value === 5000)
                    tmp = '>' + tmp;
                return tmp;
            },
        };
        this.browser = '';
        this.dataDestination = {
            list: this.vt.listeDestinations,
            todisplay: 'pays',
            topush: 'code',
            type: 'destination',
            placeholder: ' Destination'
        };
        this.dataDepart = {
            list: this.vt.airports,
            todisplay: 'city',
            topush: 'IATA',
            type: 'depart',
            placeholder: ' Ville de départ'
        };
        this.tmpParams = { minBudget: 0, maxBudget: 5000, date_depart: '' };
        this.flexibilitePersonalise = false;
        this.dureePersonalise = false;
    }
    ngOnInit() {
        this.browser = this.vt.browser;
        console.log(this.browser);
        this._resetSearchSubscription = this.vt.subscribeResetSearch((s) => {
            this.params = this.vt.params;
            this.tmpParams = this.vt.tmpParams;
            this.goSearch();
        });
        this.params = this.vt.params;
        this.regimes = this.vt.regimes;
        this.thematiques = this.vt.thematiques;
        this.tmpParams = this.vt.tmpParams;
    }
    ngOnDestroy() {
        this._resetSearchSubscription.unsubscribe();
    }
    addToParams(key, value, multipleChoice = true) {
        this.params = this.vt.addToParams(key, value, multipleChoice);
    }
    removeFromParams(key, value, multipleChoice = true) {
        // console.log(key, value, multipleChoice)
        this.vt.removeFromParams(key, value, multipleChoice);
    }
    changeBudget(event) {
        // console.log(event);
        this.vt.tmpParams.minBudget = event.value;
        this.vt.tmpParams.maxBudget = event.highValue;
        this.tmpParams = this.vt.tmpParams;
        if (event.value === 0 && event.highValue === 5000) {
            this.removeFromParams('budget', '', false);
        }
        else if (event.value === 0 && event.highValue < 5000) {
            this.vt.addToParams('budget', `<${event.highValue}`, false);
        }
        else if (event.value > 0 && event.highValue === 5000) {
            this.vt.addToParams('budget', `>${event.value}`, false);
        }
        else if (event.value > 0 && event.highValue < 5000) {
            this.vt.addToParams('budget', `${event.value}-${event.highValue}`, false);
        }
    }
    goSearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.search.emit();
        });
    }
    onkeyOnlyNumber(value) {
        return this.hlp.onkeyOnlyNumber(value);
    }
}
