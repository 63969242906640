<div>
    <div>
        <div class="btn-group">
            <div *ngIf="viewDate.getMonth()>firstDate.getMonth()||viewDate.getFullYear()>firstDate.getFullYear()"
                class="btn btn-previous" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate); refreshDate()">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div>
                <span class="disabledClick">
                    {{(viewDate | calendarDate: view + 'ViewTitle':locale).charAt(0).toUpperCase()}}{{(viewDate | calendarDate: view + 'ViewTitle':locale).slice(1)}}</span>
            </div>
            <div *ngIf="maxDate()" class="btn btn-next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate); refreshDate()">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </div>
</div>