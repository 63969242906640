import { registerLocaleData } from '@angular/common';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localeFr from '@angular/common/locales/fr';
import { RecaptchaSettings } from 'ng-recaptcha';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
registerLocaleData(localeFr, 'fr');
const ɵ0 = adapterFactory, ɵ1 = { siteKey: '6LdWeOMZAAAAAPU1b563ww0dvt15pVyreEpoTMuR', };
export class AppModule {
}
export { ɵ0, ɵ1 };
