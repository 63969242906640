import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { VendiTravelsService } from 'src/app/services/venditravels.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { Options } from 'ng5-slider';
import { url } from 'inspector';
import { environment } from 'src/environments/environment';

const _ = require('lodash');

// import listeDestinations from '../ressources/listeDestinations.json';
// import airports from '../ressources/airports.json'
// import thematiques from '../ressources/thematiques.json'


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public allOffers = {
    left: [],
    right: []
  };

  private imgurl = `${environment.apiUrl}/uploads/`;

  public partnersLoto = [
    'atoutfrance.jpg',
    'iata.png',
    'atradius.png',
    'fiskagroup.png',
    'leclubby.png'
  ]

  public homeFooter = [
    { title: '600 compagnies', description: 'Nous interrogeons plus de 600 compagnies', img: 'company.png' },
    { title: "Besoin d'aide ?", description: 'Nos experts sont à votre disposition', img: 'help.png' },
    { title: 'Des prix négociés', description: 'Des tarifs négociés spécialement pour vous', img: 'euro.png' },
    { title: 'Paiement sécurisé', description: 'Réservation simple et paiement en ligne 100% sécurisé', img: 'padlock.png' }
  ]

  constructor(
    private vt: VendiTravelsService,
    private loadingService: LoadingService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.loadingService.startWithChannel('bestoffers')
    let tmpOffersList = await this.vt.getOffers().toPromise()
    this.allOffers = this.prepareOffers(tmpOffersList)
    console.log(this.allOffers)
    this.loadingService.stopWithChannel('bestoffers')
  }

  consoletest() {
    console.log(this.vt.params)
  }

  goSearch() {
    this.router.navigate(['/search'])
  }

  prepareOffers(response) {
    console.log(response)

    response = response.map((o) => {
      if (o.img.local) {
        o.img.link = {
          background: `url(${this.imgurl}${o.img.link})`
        }
      } else {
        o.img.link = {
          background: `url(${o.img.link})`
        }
      }

      return o
    })

    const allOffers = {
      left: response.slice(0, 3),
      right: response.slice(3, 6)
    }

    return allOffers

  }

  goToOffer(id) {
    console.log(id)
    this.router.navigate([`/product/${id}`])
  }


}
