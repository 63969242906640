<section>

  <div class="container">

    <div class="searchSide">
      <app-search-component [horizontal]="false" (search)="search()"></app-search-component>
    </div>

    <div class="resultSide">

      <app-loading [channel]="'vt_search'" [search]="true">

        <div class="divInfosSearch" *ngIf="!lodash.isNil(resultSearch)">
          <span class="spanErrorSearch" *ngIf="lodash.get(resultSearch,'infos.error', false)">
            {{resultSearch['infos'].error}}
          </span>
          <span class="spanInfosSearch" *ngIf="lodash.get(resultSearch,'infos.nbProducts', false)">
            {{resultSearch['infos'].nbProducts}} résultats <span *ngIf="resultSearch['infos'].nbProducts === '100'">- <a
                class="link" (click)="search(500)">Obtenir plus de résultats</a></span>
          </span>
          <!--  <ng-container *ngIf='resultSearch.infos.nbProducts'>
                       <!-- TODO ngModel Params maxResult -> 500 
                    <button class="btn-vendi">Afficher plus de résultats</button>
                    </ng-container> -->
          <p class="text-align-center" *ngIf="lodash.get(resultSearch,'infos.ignored_criterias[0]', false)">
            Aucune offre ne correspond à votre recherche.<br>
            Nous avons ignoré

            <ng-container *ngIf='canDisplayIgnoredCriterias'>les critères suivants:
              <span class="ignoredcriterias"
                *ngFor="let criteria of resultSearch['infos'].ignored_criterias; let i = index;">
                {{critereToLabel(criteria)}}
                <ng-container *ngIf="i!== resultSearch['infos'].length -1 && criteria !== '' ">, </ng-container>
              </span>
            </ng-container>
            <ng-container *ngIf="!canDisplayIgnoredCriterias"> certains critères </ng-container>
            afin de vous proposer {{resultSearch['infos'].nbProducts}} offres alternatives.
          </p>
        </div>

        <!--                 <div class="div-horizontalscroll">
                    <div class="container-pagination">
                        <div class="left-arrow square-pagination" (click)="paginate(currentPage-1)"
                            [ngClass]="{'disable-arrow-pagination': currentPage===1}">
                            <i class="fas fa-arrow-left"></i>
                        </div>
                        <div *ngFor="let i of loopPagination" [ngClass]="{'current-page': i+1 === currentPage}"
                            (click)="paginate(i+1)" class="square-pagination">
                            {{i+1}}
                        </div>
                        <div class="right-arrow square-pagination" (click)="paginate(currentPage+1)"
                            [ngClass]="{'disable-arrow-pagination': currentPage===lastPage}">
                            <i class=" fas fa-arrow-right"></i>
                        </div>
                    </div>
                </div> -->

        <div class="container-sort">
          <div class="div-text-sort">
            <p class="p-sortby">
              Trier par <i class="fas fa-bars"></i>
            </p>
          </div>
          <div class="div-ul-sort">
            <ul class="ul-sort">
              <li (click)="getProductsSorted('price', 'asc')">Prix <i class="fas fa-arrow-right croissant"></i> </li>
              <li (click)="getProductsSorted('price', 'desc')">Prix <i class="fas fa-arrow-right decroissant"></i> </li>
              <li (click)="getProductsSorted('duration', 'asc')">Durée <i class="fas fa-arrow-right croissant"></i>
              </li>
              <li (click)="getProductsSorted('duration', 'desc')">Durée <i class="fas fa-arrow-right decroissant"></i>
              </li>
            </ul>
          </div>
        </div>

        <div class="containerResultSearch">
          <ng-container *ngIf="resultSearch">
            <div class="containerProduct" *ngFor=" let product of productsDisplay">

              <div class="divImgProduct"
                [ngStyle]="{'background-image':'url('+product.photo+'), url(assets/default-picture.png)'}">
              </div>

              <div class="title-mobile" [innerHTML]="product.title"></div>

              <div class="divDescriptionProduct">
                <span class="spanTitleProduct" [innerHTML]="product.title"></span><br>
                <span class="span-destination">{{product.tos.city}} <ng-container
                    *ngIf='product.tos.city && product.tos.country'> - </ng-container>
                  <!--  <a class="a-link"
                                        (click)="search(100, product.tos.country)"> -->{{product.tos.country}}
                  <!-- </a> --></span>

                <!-- 
                                <p class="pListeDepart">
                                    <i class="fas fa-plane"></i>
                                    <ng-container *ngFor="let from of product.froms;  let i = index">
                                        {{from}}
                                        <ng-container *ngIf="i+1 !== product.froms.length">, </ng-container>
                                        <ng-container *ngIf="i === 4"> <br> </ng-container>
                                    </ng-container>
                                </p>
                                -->

                <p class="description" [innerHTML]="product.description"></p>

                <div class="infos-theme" *ngIf='product.themes[0]'>
                  <span *ngFor="let theme of product.themes; let i = index" [attr.data-index]="i">
                    {{ i ===0 ? theme : theme.toLowerCase()}}
                    <ng-container *ngIf="i != product.themes.length -1">,</ng-container>
                  </span>
                </div>


              </div>


              <div class="detailsProduct">

                <p>
                  <span class="grey-color">{{product.duration.day}} jour<ng-container *ngIf="product.duration.day>1">s
                    </ng-container> /
                    {{product.duration.night}} nuit<ng-container *ngIf="product.duration.night>1">s
                    </ng-container></span>
                  <br>dès <span class="span-price">{{ product.realBestPrice.amount }}€</span>
                </p>

                <!-- <a href="{{product.url}}" target="_blank"> -->
                <a routerLink="/product/{{product.id}}" [state]='product.realBestPrice'>
                  <button class="btn-vendi">Voir l'offre</button>
                </a>
              </div>

            </div>
          </ng-container>
        </div>

        <div class="c">
          <div class="container-pagination" *ngIf="lastPage > 1 ">
            <div class="left-arrow square-pagination" (click)="paginate(currentPage-1)"
              [ngClass]="{'disable-arrow-pagination': currentPage===1}">
              <i class="fas fa-arrow-left"></i>
            </div>
            <div *ngFor="let i of loopPagination" [ngClass]="{'current-page': i+1 === currentPage}"
              (click)="paginate(i+1)" class="square-pagination">
              {{i+1}}
            </div>
            <div class="right-arrow square-pagination" (click)="paginate(currentPage+1)"
              [ngClass]="{'disable-arrow-pagination': currentPage===lastPage}">
              <i class=" fas fa-arrow-right"></i>
            </div>
          </div>
        </div>

      </app-loading>

    </div>
  </div>

</section>
