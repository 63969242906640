<section class="sectionVT">

    <div class="background-search">
        <app-search-component [horizontal]="true" (search)="goSearch()"></app-search-component>
    </div>

    <app-loading [channel]="'bestoffers'">

        <div class="title-bestoffers">
            <h1>
                NOS MEILLEURS OFFRES DE VOYAGES
            </h1>
            <span>
                Retrouvez ici les meilleurs tarifs pour vos destinations préférés
            </span>
        </div>

        <div class="container-offers">


            <div class="left-side">
                <div *ngFor="let offer of allOffers.left" class="div-offer" (click)="goToOffer(offer.idresa)">
                    <div class="background-offer" [ngStyle]="offer.img.link"></div>
                    <div class="overlay-offer">
                        <div class="txt-overlay">
                            <p>
                                <span class="span-country" *ngIf="offer.to.country">{{offer.to.country}}</span>
                                <br *ngIf="offer.to.city && offer.to.country">
                                <span *ngIf="offer.to.city"> {{offer.to.city}}</span>
                            </p>
                            <hr class="hr-offer">
                            <p class="p-onhover-offer-mobile">
                                <span>{{offer.title}}</span>
                                <!-- <ng-container *ngIf="offer.description"> <br> {{offer.description}}</ng-container> -->
                                <ng-container *ngIf="offer.duration">
                                    <br>
                                    <span class="betweendot">
                                        {{offer.duration.day}} jour<ng-container *ngIf="offer.duration.day > 1">s
                                        </ng-container>
                                        / {{offer.duration.night}} nuit<ng-container *ngIf="offer.duration.night > 1">s
                                        </ng-container></span>
                                </ng-container>
                                <br>
                            </p>
                            <p class="p-default-offer">
                                <span class="betweendot">À partir de</span> <br>
                                <span style="font-weight: bold;">
                                    {{offer.bestprice}} €
                                </span>
                            </p>
                            <p class="p-onhover-offer">
                                {{offer.title}}
                                <!-- <ng-container *ngIf="offer.description"> <br> {{offer.description}}</ng-container> -->
                                <ng-container *ngIf="offer.duration">
                                    <br>
                                    {{offer.duration.day}} jour<ng-container *ngIf="offer.duration.day > 1">s
                                    </ng-container>
                                    / {{offer.duration.night}} nuit<ng-container *ngIf="offer.duration.night > 1">s
                                    </ng-container>
                                </ng-container>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="right-side">
                <div *ngFor="let offer of allOffers.right" class="div-offer" (click)="goToOffer(offer.idresa)">
                    <div class="background-offer" [ngStyle]="offer.img.link"></div>
                    <div class="overlay-offer">
                        <div class="txt-overlay">
                            <p>
                                <span class="span-country" *ngIf="offer.to.country">{{offer.to.country}}</span>
                                <br *ngIf="offer.to.city && offer.to.country">
                                <span *ngIf="offer.to.city"> {{offer.to.city}}</span>
                            </p>
                            <hr class="hr-offer">
                            <p class="p-onhover-offer-mobile">
                                <span>{{offer.title}}</span>
                                <!-- <ng-container *ngIf="offer.description"> <br> {{offer.description}}</ng-container> -->
                                <ng-container *ngIf="offer.duration">
                                    <br>
                                    <span class="betweendot">
                                        {{offer.duration.day}} jour<ng-container *ngIf="offer.duration.day > 1">s
                                        </ng-container>
                                        / {{offer.duration.night}} nuit<ng-container *ngIf="offer.duration.night > 1">s
                                        </ng-container></span>
                                </ng-container>
                                <br>
                            </p>
                            <p class="p-default-offer">
                                <span class="betweendot">À partir de</span> <br>
                                <span style="font-weight: bold;">
                                    {{offer.bestprice}} €
                                </span>
                            </p>
                            <p class="p-onhover-offer">
                                {{offer.title}}
                                <!-- <ng-container *ngIf="offer.description"> <br> {{offer.description}}</ng-container> -->
                                <ng-container *ngIf="offer.duration">
                                    <br>
                                    {{offer.duration.day}} jour<ng-container *ngIf="offer.duration.day > 1">s
                                    </ng-container>
                                    / {{offer.duration.night}} nuit<ng-container *ngIf="offer.duration.night > 1">s
                                    </ng-container>
                                </ng-container>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </app-loading>

</section>

<div class="footer-home">
    <div class="top-footer">
        <p>
            NOS GARANTIES
        </p>
        <div class="container-logo-partners">
            <img *ngFor="let img of partnersLoto" src="assets/logo-partners/{{img}}" class="logo-partner">
        </div>
    </div>
    <div class="bottom-footer">

        <div class="container-items">
            <div class="container-item" *ngFor="let item of homeFooter">
                <div class="container-icon-footer">
                    <img src="assets/home-icons/{{item.img}}" class="home-icons">
                </div>
                <div class="txt-icon">
                    <span class="title-item-footer">{{item.title}}</span><br>
                    <span class="description-item-footer">{{item.description}}</span>
                </div>
            </div>
        </div>


    </div>
</div>