import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { VendiTravelsService } from './services/venditravels.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { HelperService } from './services/helper.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'VendiTravels';
  isMenuOpen: Boolean = false;
  private deviceInfo;
  public ie: Boolean = false;
  private _openSideNav: Subscription;

  constructor(
    private vt: VendiTravelsService,
    private router: Router,
    private hlp: HelperService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.vt.previousUrl = this.vt.currentUrl;
      this.vt.currentUrl = event.url;

    });

    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.browser == 'IE') this.ie = true;
    this.vt.browser = this.deviceInfo.browser;
    console.log(this.deviceInfo.browser)

    this._openSideNav = this.vt.subscribeSideNavMenu((s) => {
      this.isMenuOpen = !this.isMenuOpen;
      console.log('event sidenavmenu')
    })

  }

  changePage(value) {
    this.vt.resetSearch('type', value)
    this.isMenuOpen = false;
  }

  goHome() {
    this.router.navigate(['/'])
    this.isMenuOpen = false;

  }

  ngOnDestroy() {
    this._openSideNav.unsubscribe()
  }

  scrollTop() {
    this.hlp.scrollTop()
  }



}

@Component({
  templateUrl: './pages/cgv/conditions-generales-de-vente.html',
})
export class CGV {
  constructor(
  ) { }
}


@Component({
  templateUrl: './pages/cgv/mentions-legales.html',
})
export class MentionsLegales {
  constructor(
  ) { }
}