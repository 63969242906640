import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

// import listeDestinations from '../pages/venditravels/ressources/listeDestinations.json';
// import airports from '../pages/venditravels/ressources/airports.json';
// import thematiques from '../pages/venditravels/ressources/thematiques.json';

import listeDestinations from '../resources/listeDestinations.json';
import airports from '../resources/airports.json';
import thematiques from '../resources/thematiques.json';
import { Subscription } from 'rxjs';
import { EventBus } from './utils/models/event-bus.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class VendiTravelsService {

    private _resetSearchEventBus: EventBus<('')> = new EventBus<('')>();
    private _sideNavMenuEventBus: EventBus<('')> = new EventBus<('')>();

    public url = environment.apiUrl;
    public params = {
        date_depart: [], souplesse: [], depart: [], destination: [], type: [], budget: [], departement: [], region: [],
        regioncp: [], codepostal: [], iata_destination: [], maxresult: [], regime: [], nuits: [], motcle: [], titre: [],
        promo: [], promo_only: [], date_min: [], date_max: []
    };
    public paramslabel = {
        date_depart: 'Date de départ', souplesse: 'Flexibilité', depart: 'Ville de départ', destination: 'Destination', type: 'Thématique', budget: 'Budget', departement: '', region: '',
        regioncp: '', codepostal: '', iata_destination: '', maxresult: '', regime: 'Formule', nuits: 'Nombre de nuits', motcle: 'Mot clé', titre: 'Titre',
        promo: 'Promotion', promo_only: '', date_min: '', date_max: ''
    };

    public tmpParams = {
        minBudget: 0, maxBudget: 5000, date_depart: ''
    };

    public listeDestinations = listeDestinations;
    public airports = airports;
    public thematiques = thematiques;

    public browser = '';

    public regimes = [
        { code: 'RO', libelle: 'Logement seul', }, //ROOM ONLY
        { code: 'BB', libelle: 'Petit-déjeuner' },  //BED & BREAKFAST
        { code: 'HB', libelle: 'Demi-pension' },  //HALF BOARD
        { code: 'FB', libelle: 'Pension complète' }, //FULLBOARD
        { code: 'AI', libelle: 'Tout compris' } //ALL INCLUSIVE
    ];

    public previousUrl: string = null;
    public currentUrl: string = null;

    public oldResultSearch:any = false;
    public paginationSearch = 1;

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }


    public removeFromParams(key, value, multipleChoice = true) {
        if (multipleChoice) {
            const index = this.params[key].indexOf(value)
            this.params[key].splice(index, 1)
        } else {
            this.params[key] = []
        }

        return this.params

    }

    public addToParams(key, value, multipleChoice = true) {

        if (value === '') { return this.removeFromParams(key, value, multipleChoice) }
        else {
            switch (key) {
                case 'destination':
                case 'depart':
                    // TODO 
                    break;
                case 'date_depart':
                    value = this.convertDate(value.value)
                    // TODO 
                    break;
                case 'budget':

                    // if (value === 'min') console.log(this.minBudget)
                    // else console.log(this.maxBudget)

                    break
                default:
                    break;
            }

            if (multipleChoice) this.params[key].push(value)
            else this.params[key] = [value]

            return this.params

        }

    }

    public resetParams() {
        this.params = {
            date_depart: [], souplesse: [], depart: [], destination: [], type: [], budget: [], departement: [], region: [],
            regioncp: [], codepostal: [], iata_destination: [], maxresult: [], regime: [], nuits: [], motcle: [], titre: [],
            promo: [], promo_only: [], date_min: [], date_max: []
        };
    }

    public convertDate(date) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        let d = new Date(date)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

    public search(params) {
        return this.http.post(this.url + `api/venditravels/search`, params)
    }

    public getProduct(id) {
        return this.http.get(this.url + `api/venditravels/getProduct/${id}`)
    }

    public critereToLabel(critere) {
        // console.log('critere : ', critere);
        // console.log('paramslabel[critere] : ', this.paramslabel[critere]);

        if (this.paramslabel[critere]) return this.paramslabel[critere]
        else return false;
    }

    public getLinkDevis(product) {
        const link = 'https://www.speedresa.com/moteurs/sra2/devis.php?';
        const agence = '&AGENCE=V30'

        return `${link}villeDepart=${product.from.code}&dateDepart=${product.date}&duree=${product.duration.night}&IDvoyage=${product.id}${agence}`

    }

    public getOffers() {
        return this.http.get(this.url + `api/venditravels/offers`)
    }

    public subscribeResetSearch(callback: (s: string) => void): Subscription {
        return this._resetSearchEventBus.subscribe(callback);
    }

    public subscribeSideNavMenu(callback: (s: string) => void): Subscription {
        return this._sideNavMenuEventBus.subscribe(callback);

    }

    public activeSideNavMenu() {
        this._sideNavMenuEventBus.publish('')
    }


    public resetSearch(key = '', value = '') {

        this.params = {
            date_depart: [], souplesse: [], depart: [], destination: [], type: [], budget: [], departement: [], region: [],
            regioncp: [], codepostal: [], iata_destination: [], maxresult: [], regime: [], nuits: [], motcle: [], titre: [],
            promo: [], promo_only: [], date_min: [], date_max: []
        };

        this.tmpParams = {
            minBudget: 0, maxBudget: 5000, date_depart: ''
        };
        if (key && value) this.params[key] = [String(value)]

        if (this.router.url == '/' || this.router.url == '/search')
            this._resetSearchEventBus.publish('')
        else
            this.router.navigate(['/search'])

    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    sendInfosRequest(formInfos) {
        return this.http.post(this.url + `api/venditravels/infos-request`, formInfos)
    }


}