import { Component, OnDestroy, OnInit, DoCheck } from "@angular/core";
import { Input } from "@angular/core";
import { Subscription } from "rxjs";
import { LoadingService } from "../../../services/loading.service";
// import { Promise } from 'es6-promise';
export type LoadingStyle = "PROGRESS" | "PROGRESS_DETERMINATE" | "CYCLE";
export type LoadingColor = "BLUE" | "GREEN" | "YELLOW" | "RED";
export type LoadingSize = "BIG" | "MEDIUM" | "SMALL" | "TINY";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class AppLoadingComponent implements OnDestroy, OnInit, DoCheck {

  private _channel: string = "";


  @Input() set channel(v: string) {
    console.log(`AppLoadingComponent : current channel ${this._channel}`)
    console.log(`AppLoadingComponent : new channel ${v}`)
    this._channel = v;
  };

  get channel(): string {
    return this._channel;
  }

  @Input() search: Boolean = false;
  @Input() style: LoadingStyle;
  @Input() color: LoadingColor;
  @Input() size: LoadingSize;

  public isLoading: boolean = false;
  public currentPercent: number = 0;

  private _isLoadingToCommit: boolean = false;
  private _currentPercentToCommit: number = 0;
  private _haveToCommit: boolean = false;

  private _loadingStartSubscription: Subscription;
  private _loadingStopSubscription: Subscription;
  private _percentChangeSubscription: Subscription;


  constructor(
    /* private _logger : LoggerService, */
    private _loadingService: LoadingService) { }

  ngDoCheck() {
    if (this._haveToCommit) {
      this._haveToCommit = false;
      this.isLoading = this._isLoadingToCommit;
      this.currentPercent = this._currentPercentToCommit;
    }
  }

  ngOnInit() {

    if (!this.channel)
      this.channel = LoadingService.GLOBAL_CHANNEL;

    if (!this.style)
      this.style = "CYCLE";

    if (!this.color)
      this.color = "RED";

    if (!this.size)
      this.size = "MEDIUM";

    this.isLoading = this._loadingService.checkIfLoading(this.channel);

    this._loadingStartSubscription = this._loadingService
      .subscribeToStartLoading((s: [string, string]) => {
        this._handleStartLoading(s[0], s[1]);
      });

    this._loadingStopSubscription = this._loadingService
      .subscribeToStopLoading((s: string) => {
        this._handleStopLoading(s);
      });

    this._percentChangeSubscription = this._loadingService
      .subscribeToChangePercent((s: [string, number]) => {
        this._handleChangePercent(s[0], s[1]);
      });
  }

  ngOnDestroy() {
    this._loadingStartSubscription.unsubscribe();
    this._loadingStopSubscription.unsubscribe();
    this._percentChangeSubscription.unsubscribe();
  }

  public isProgress(): boolean {
    return (this.style === "PROGRESS")
  }

  public isProgressDeterminate(): boolean {
    return (this.style === "PROGRESS_DETERMINATE")
  }

  public isCycle(): boolean {
    return (this.style === "CYCLE")
  }

  public getColorClass(): string {
    let result = "";
    switch (this.color) {
      case "BLUE":
        result = "spinner-blue-only";
        break;
      case "RED":
        result = "spinner-red-only";
        break;
      case "YELLOW":
        result = "spinner-yellow-only";
        break;
      case "GREEN":
        result = "spinner-green-only";
        break;
      default:
        result = "spinner-red-only";
    }

    return result;
  }

  public getSizeClass(): string {
    let result = "";

    switch (this.size) {
      case "MEDIUM":
        result = "";
        break;
      case "SMALL":
        result = "small";
        break;
      case "TINY":
        result = "tiny";
        break;
      case "BIG":
        result = "big";
        break;
      default:
        result = "";
    }

    return result;
  }

  private _handleStartLoading(channel: string, message: string) {


    if (channel === this.channel) {
      this._isLoadingToCommit = true;
      this._currentPercentToCommit = 0;
      this._haveToCommit = true;
    }
  }

  private _handleStopLoading(channel: string) {
    if (channel === this.channel) {
      this._isLoadingToCommit = false;
      this._haveToCommit = true;
    }
  }

  private _handleChangePercent(channel: string, percent: number) {
    if (channel === this.channel) {
      this._currentPercentToCommit = percent;
      this._haveToCommit = true;
    }
  }

}
