<!-- <ng-container *ngIf="!ie"> -->

    <app-header></app-header>

    <mat-sidenav-container>

        <mat-sidenav [(opened)]="isMenuOpen" position="end" mode="over">
            <div class="container-side">
                <ul class="listonglets">
                    <li routerLink='/'>ACCUEIL</li> <span class="bar"></span>
                    <li><a href="https://venditravels-vol.resatravel.com/?">VOLS</a></li> <span class="bar"></span>
                    <li (click)="changePage(1)">SÉJOURS</li> <span class="bar"></span>
                    <li (click)="changePage(2)">CIRCUITS</li> <span class="bar"></span>
                    <li (click)="changePage(21)">LOCATIONS</li> <span class="bar"></span>
                    <li (click)="changePage(5)">CROISIÈRES</li> <span class="bar"></span>
                    <li (click)="changePage(3)">WEEK-END</li>
                </ul>

                <div class="footer-side">
                    <a href="tel:+33387885670">03 87 88 09 30<i class="fas fa-phone-alt"></i></a><br>
                    <a href="mailto:info@venditravels.com">info@venditravels.com<i class="fas fa-envelope"></i></a>
                </div>
            </div>
        </mat-sidenav>

        <mat-sidenav-content>

            <router-outlet></router-outlet>
            
            <div class="gotop" (click)="scrollTop()">
                <i class="fa fa-angle-up top-icon"></i>
            </div>

        </mat-sidenav-content>

    </mat-sidenav-container>

    <app-footer></app-footer>

<!-- </ng-container> -->

<!-- 
<div class="page-iebrowser" *ngIf="ie">
    <div class="div-iebrowser">
        <div class="container-img-iebrowser">
            <img src="assets/venditravels-logo.png">
        </div>
        <p style="color:black">Cher utilisateur,<br><br>
            Nous avons constaté que vous utilisez Internet Explorer pour consulter ce site.
            <br><br>
            Internet Explorer étant obsolète, plusieurs erreurs peuvent surgir en l'utilisant
            <br><br>
            Pour accéder à VendiTravels nous vous conseillons d’installer un navigateur moderne comme
            <a href="https://www.google.fr/chrome/" target="_blank">Google Chrome</a>,
            <a href="https://www.mozilla.org/fr/" target="_blank">Firefox</a> ou
            <a href="https://www.opera.com/fr" target="_blank">Opera</a>
            .<br><br>
            Nous vous remercions de votre compréhension.</p>
        L'équipe VendiTravels.
    </div>
</div> -->