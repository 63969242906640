import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ViewChildren, ChangeDetectionStrategy, TemplateRef, SimpleChanges, OnChanges, QueryList, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VendiTravelsService } from 'src/app/services/venditravels.service';
import { LoadingService } from 'src/app/services/loading.service';
// import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { read } from 'fs';
import { CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK, } from 'angular-calendar';
import { title } from 'process';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { log } from 'console';
import { vi } from 'date-fns/locale';
import { isThisISOWeek } from 'date-fns';

const _ = require('lodash');
const htmlToText = require('html-to-text');

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})

export class ProductComponent implements OnInit, AfterViewChecked {

  @ViewChild('longDescription', { static: false }) longDescription: ElementRef;
  @ViewChild('pDescription', { static: false }) pDescription: ElementRef;

  public product;
  public infosRequest: Boolean = false;
  public arrayDescription;
  public bestprice;
  public descriptionToLong: Boolean = false;
  public showAllDescription: Boolean = false;

  public view: CalendarView = CalendarView.Month;
  public viewDate: Date = new Date();
  public events: CalendarEvent[] = [];
  public locale: string = 'fr';
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  public CalendarView = CalendarView;
  public lastDate: Date = new Date();
  public firstDate: Date = new Date();
  public productIsAvailable: Boolean = false;

  public slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };
  public lodash = _;
  public transportSelected;
  public listMonths = []
  public months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  public colorEvent = {
    primary: '#034ea2',
    secondary: '#03a08bff',
  };
  public formCalendar = new FormGroup({
    villeDeDepart: new FormControl(),
    dateDeDepart: new FormControl()
  });

  public villeDeDepart = new FormControl('', Validators.required);
  public dateDeDepart = new FormControl('', Validators.required);
  public dataInitialized: Boolean = false;
  public positionTopDescription: number = 0;
  public formInfosIsSubmitted: Boolean = false;
  public tmpDateForSelect;
  public tmpCitySelect;
  public cgvAccepted: Boolean = false;

  public readyToCalculateDescriptionHeight: Boolean = false;
  public disableCalendar = false;
  public realBestPrice;

  public infosRequestObject = {
    gender: '',
    firstname: '',
    lastname: '',
    postalcode: '',
    address: '',
    city: '',
    country: '',
    email: '',
    tel: '',
    participants: {
      adults: '',
      children: '',
      babies: '',
    },
    details: '',
    product: {
      title: '',
      id: '',
      tos: ''
    }
  }

  public formInfos = new FormGroup({
    email: new FormControl(this.infosRequestObject.email, [Validators.required, Validators.email]),
    firstname: new FormControl(this.infosRequestObject.firstname, [Validators.required, Validators.minLength(1)]),
    lastname: new FormControl(this.infosRequestObject.lastname, [Validators.required, Validators.minLength(1)]),
    gender: new FormControl(this.infosRequestObject.gender, [Validators.required]),
    postalcode: new FormControl(this.infosRequestObject.postalcode, Validators.pattern('[0-9]{5}')),
    tel: new FormControl(this.infosRequestObject.tel, [Validators.required, Validators.pattern(`^(0|\\+33)[1-9]([-. ]?[0-9]{2}){4}$`)]),
    address: new FormControl(this.infosRequestObject.address),
    city: new FormControl(this.infosRequestObject.city),
    country: new FormControl(this.infosRequestObject.country),
    adults: new FormControl(this.infosRequestObject.participants.adults),
    children: new FormControl(this.infosRequestObject.participants.children),
    babies: new FormControl(this.infosRequestObject.participants.babies),
    details: new FormControl(this.infosRequestObject.details),
  });
  public reactiveForm: FormGroup = new FormGroup({
    recaptchaReactive: new FormControl(null, Validators.required)
  });

  private indexOfIataAtStart = 0;

  constructor(
    private route: ActivatedRoute,
    private vt: VendiTravelsService,
    private loadingService: LoadingService,
    private cdRef: ChangeDetectorRef,
    private hlp: HelperService,
    private router: Router,
  ) {

    try {
      const navigation = this.router.getCurrentNavigation();
      this.realBestPrice = navigation.extras.state;
    } catch (error) {

    }

  }
  ngOnInit() {

    this.loadingService.startWithChannel('vt_product')

    this.route.paramMap.subscribe(async params => {

      let idproduct = params.get('id');
      this.product = await this.vt.getProduct(idproduct).toPromise();

      this.productIsAvailable = !(_.get(this.product, '[0].label[0]', '').toLowerCase() === 'erreur');

      if (!this.productIsAvailable) {
        this.hlp.message('Offre indisponible', 'error')
        this.router.navigate(["/"]);
      }

      if (_.get(this.realBestPrice, 'from', '')) {
        console.log("realBestPrice : ", this.realBestPrice)

        if (!this.realBestPrice.from.code || this.realBestPrice.from.code === "0") {
          this.realBestPrice.from = {
            label: "Sans transport",
            code: '0'
          };
        }
        else {
          this.realBestPrice.from.label = this.getAirportLabel(this.realBestPrice.from.code);
        }
      }

      if (_.get(this.realBestPrice, 'from.code', false)) this.tmpCitySelect = this.realBestPrice.from.code;
      else this.tmpCitySelect = this.product.froms[0].iata[0];

      this.arrayDescription = Array(this.product.description.length - 1).fill(1).map((x, i) => i + 1);

      console.log('B4')

      this.prepareProduct();
      this.prepareCalendar();

      this.dataInitialized = true;

    })

  }
  getAirportLabel(iata) {
    const airportsList = this.vt.airports;
    const label = airportsList.find((i) => i.IATA.toLowerCase() === iata.toLowerCase()).city;
    return label;
  }

  ngAfterViewChecked() {
    if (this.readyToCalculateDescriptionHeight) {
      this.readyToCalculateDescriptionHeight = false;
      this.positionTopDescription = this.longDescription.nativeElement.offsetTop;
      if (this.pDescription.nativeElement.scrollHeight > this.pDescription.nativeElement.offsetHeight)
        this.descriptionToLong = true;
      this.cdRef.detectChanges();
    }
  }

  prepareCalendar() {

    if (_.get(this.realBestPrice, 'from.code', false)) {

      this.indexOfIataAtStart = this.product.froms.findIndex((f) => f.iata[0] === this.realBestPrice.from.code);

      if (~this.indexOfIataAtStart) { //( => équivalent à === -1)
        this.tmpCitySelect = this.product.froms[this.indexOfIataAtStart].iata
      }
      else {
        this.tmpCitySelect = this.product.froms[0].iata;
        this.indexOfIataAtStart = 0;
      }
    }
    else {
      this.indexOfIataAtStart = 0;
      this.tmpCitySelect = this.product.froms[0].iata;
    }

    let index = 1;
    let tmpLastDate = this.product.prices.all[this.product.prices.all.length - index].date;

    for (let i = 2; i < this.product.prices.all.length; i++) {
      const currentDate = new Date(this.product.prices.all[this.product.prices.all.length - i].date);
      if (currentDate > new Date(tmpLastDate[0])) { tmpLastDate = this.product.prices.all[this.product.prices.all.length - i].date; }
      else break;
    }

    this.lastDate = new Date(tmpLastDate);
    let tmpfirstDate = this.product.prices.all[0].date;
    this.firstDate = new Date(tmpfirstDate);

    const tmpFirst = {
      year: this.firstDate.getFullYear(),
      month: this.firstDate.getMonth()
    };

    this.tmpDateForSelect = tmpFirst.year + '-' + tmpFirst.month;

    const tmpLast = {
      year: this.lastDate.getFullYear(),
      month: this.lastDate.getMonth()
    };
    let monthSelected = new Date().getMonth();
    let yearSelected = new Date().getFullYear();

    if (tmpFirst.month > monthSelected && tmpFirst.year <= yearSelected) {
      monthSelected = tmpFirst.month + 1;
      this.viewDate = new Date(new Date().getFullYear(), monthSelected, 0, 0, 0, 0, 0);
    } else if (tmpFirst.month < monthSelected && tmpFirst.year > yearSelected) {
      this.viewDate = new Date(tmpFirst.year, tmpFirst.month + 1, 0, 0, 0, 0, 0);
    }
    else if (tmpFirst.year > yearSelected) {
      this.viewDate.setFullYear(tmpFirst.year);
    }

    const oneYear = Array(12).fill(1).map((x, i) => i);
    const nbOfYear = tmpLast.year - tmpFirst.year + 1;

    let tmpInf: Number = 0;
    let tmpSup: Number = 0;

    for (let i = 0; i < nbOfYear; i++) {

      if (i === 0) tmpInf = tmpFirst.month;
      else tmpInf = 0;

      if (i === nbOfYear - 1) tmpSup = tmpLast.month;
      else tmpSup = 11;

      const newYear = {
        year: tmpFirst.year + i,
        month: oneYear.filter((y) => y >= tmpInf && y <= tmpSup)
      }

      this.listMonths.push(newYear)

    }

    this.loadingService.stopWithChannel('vt_product');

    if (this.realBestPrice && this.realBestPrice.date) {
      this.viewDate = new Date(this.realBestPrice.date);
      const month = this.viewDate.getMonth();
      const year = this.viewDate.getFullYear();
      this.tmpDateForSelect = `${year}-${month}`;
    }

    this.formCalendar.patchValue({
      dateDeDepart: this.listMonths[0],
      villeDeDepart: this.product.froms[this.indexOfIataAtStart].iata[0]
    });

    this.getPrices()

  }

  prepareProduct() {

    /* IL EST OUUUUUUUUUU LE BUGGGGGG ???!!!???!?!!!!???!?!?!?!?!?!! */

    let cheaperOffer = this.getTheCheaperOffer();

    if (!_.get(this.realBestPrice, 'date', false)) {
      _.set(this.realBestPrice, 'date', cheaperOffer.date)
    }

    this.product.description[0].txt = htmlToText.fromString(this.product.description[0].txt);

    this.product.photo = this.product.photo.map((p) => {
      return { background: 'url(' + p + ')' }
    });

    this.readyToCalculateDescriptionHeight = true;

  }
  getMonth(nb) {
    const i = this.months.indexOf(nb);
    return this.months[i];
  }

  changeMonth(event) {

    if (!event.value) this.viewDate = new Date();
    else {
      let tmpMonth = parseFloat(event.value.split('-')[1]) // + 1;
      this.viewDate = new Date(event.value.split('-')[0], tmpMonth, 1) //, 0, 0, 0, 0)
    }
    this.getPrices()

  }

  changeTransport(event) {
    this.transportSelected = event.value[0];
    this.getPrices()
  }

  getTheCheaperOffer() {
    const today = new Date().getTime();
    const bestprice: number = this.product.prices.best[0].amount[0];
    const allprices = this.product.prices.all;

    let samePrices = allprices.filter((p) => {
      return p.amount[0] === bestprice
    })
    const sortFunction = (a, b) => new Date(a.date[0]).getTime() - new Date(b.date[0]).getTime()

    samePrices = samePrices.filter((p) => {
      return new Date(p.date[0]).getTime() >= today
    })

    let tmp = samePrices.sort(sortFunction)[0]

    this.bestprice = {
      id: this.product.id,
      price: tmp.amount[0],
      duration: {
        day: tmp.duration[0].day[0],
        night: tmp.duration[0].night[0]
      },
      date: tmp.date[0],
      from: {
        label: tmp.from[0].airport[0].ref[0] === '0' ? 'Sans transport'
          : 'départ ' + _.find(this.vt.airports, ((airport) => airport.IATA === tmp.from[0].airport[0].ref[0])).city,
        code: tmp.from[0].airport[0].ref[0]
      }

    }

    return this.bestprice;
  }
  getLink(price = this.bestprice) {
    price.id = this.product.id;
    return this.vt.getLinkDevis(price);
  }

  goDescription() {
    window.scrollTo({
      top: this.positionTopDescription,
      behavior: 'smooth',
    })
  }

  getPrices() {
    const y = this.viewDate.getFullYear();
    const m = this.viewDate.getMonth();
    const firstDay = new Date(y, m, 1).getTime();
    const lastDay = new Date(y, m + 1, 1).getTime();
    this.loadingService.startWithChannel('calendar');
    if (this.indexOfIataAtStart !== -1) {
      this.transportSelected = String(this.product.froms[this.indexOfIataAtStart].iata[0]);
      this.indexOfIataAtStart = -1;
    }
    else {
      if (!this.transportSelected) this.transportSelected = String(_.get(this.product, 'froms[0].iata[0]'));
      if (this.product.prices.all.length <= 1) this.disableCalendar = true;
    }

    const filtredprice = this.product.prices.all.filter((p) => {

      const pDate = new Date(p.date[0]).getTime();

      //========== PER MONTH ==========//
      // if (p.from[0].airport[0].ref[0] === this.transportSelected && pDate >= firstDay && pDate <= lastDay) {
      // return p
      // }

      //========== ALL DATE ==========//
      if (p.from[0].airport[0].ref[0] === this.transportSelected) {
        return p;
      }

    })

    /* return */ this.pricesToCalendarEvents(filtredprice);
  }

  pricesToCalendarEvents(filtredprice) {

    this.events = filtredprice.map((e) => {
      return {
        title: `${e.amount[0]}&&${e.promo[0]}`,
        color: this.colorEvent,
        start: new Date(e.date[0]),
        cssClass: 'custom-events',
      };

    });

    //===== Le bon mois est affiché mais viewDate n'est pas init donc : =====//
    if (this.dataInitialized === false) {

      this.viewDate = new Date(this.viewDate.setMonth(this.viewDate.getMonth() /* + 1 */));
    }

    this.loadingService.stopWithChannel('calendar');
  }

  consoleDayEvents(event) {

    if (event.length) {
      const tmpDate = this.vt.formatDate(event[0].start)
      const url = `https://www.speedresa.com/moteurs/sra2/devis.php?villeDepart=${this.transportSelected}&dateDepart=${tmpDate}&duree=${this.product.duration.night}&IDvoyage=${this.product.id}&AGENCE=V30`
      window.open(url, "_blank");
    }

  }

  refreshdate() {
    this.tmpDateForSelect = this.viewDate.getFullYear() + '-' + this.viewDate.getMonth();

  }

  txtLimite(length) {
    this.hlp.txtLimite(length, 5000)
  }

  submitForm() {
    this.formInfosIsSubmitted = true;

    if (this.formInfos.valid && this.cgvAccepted && !this.reactiveForm.invalid) {

      this.infosRequestObject.product = {
        title: this.product.title,
        id: this.product.id,
        tos: this.product.tos
      }

      this.vt.sendInfosRequest(this.infosRequestObject).toPromise().then((response: any) => {
        if (response.status) {
          this.hlp.message('Demande envoyée', 'success');
          this.infosRequest = false;
          this.hlp.scrollTop()
          this.cgvAccepted = false;
          this.reactiveForm.reset();
        }
        else {
          this.hlp.message("Une erreur s'est produite veuillez réessayer ultérieurement", "error");
        }
      })

    }

  }

  /*   print() {
      const url = `https://www.venditravels.com/produit/pdf/package/${this.product.id}-131/${_.kebabCase(this.product.title)}/`
      window.open(url, '_blank')
    } */
  /* 
  resolved(captchaResponse: string, res) {
    
  }

 */

}

