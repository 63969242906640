import { environment } from "../../environments/environment";
// import listeDestinations from '../pages/venditravels/ressources/listeDestinations.json';
// import airports from '../pages/venditravels/ressources/airports.json';
// import thematiques from '../pages/venditravels/ressources/thematiques.json';
import listeDestinations from '../resources/listeDestinations.json';
import airports from '../resources/airports.json';
import thematiques from '../resources/thematiques.json';
import { EventBus } from './utils/models/event-bus.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class VendiTravelsService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this._resetSearchEventBus = new EventBus();
        this._sideNavMenuEventBus = new EventBus();
        this.url = environment.apiUrl;
        this.params = {
            date_depart: [], souplesse: [], depart: [], destination: [], type: [], budget: [], departement: [], region: [],
            regioncp: [], codepostal: [], iata_destination: [], maxresult: [], regime: [], nuits: [], motcle: [], titre: [],
            promo: [], promo_only: [], date_min: [], date_max: []
        };
        this.paramslabel = {
            date_depart: 'Date de départ', souplesse: 'Flexibilité', depart: 'Ville de départ', destination: 'Destination', type: 'Thématique', budget: 'Budget', departement: '', region: '',
            regioncp: '', codepostal: '', iata_destination: '', maxresult: '', regime: 'Formule', nuits: 'Nombre de nuits', motcle: 'Mot clé', titre: 'Titre',
            promo: 'Promotion', promo_only: '', date_min: '', date_max: ''
        };
        this.tmpParams = {
            minBudget: 0, maxBudget: 5000, date_depart: ''
        };
        this.listeDestinations = listeDestinations;
        this.airports = airports;
        this.thematiques = thematiques;
        this.browser = '';
        this.regimes = [
            { code: 'RO', libelle: 'Logement seul', },
            { code: 'BB', libelle: 'Petit-déjeuner' },
            { code: 'HB', libelle: 'Demi-pension' },
            { code: 'FB', libelle: 'Pension complète' },
            { code: 'AI', libelle: 'Tout compris' } //ALL INCLUSIVE
        ];
        this.previousUrl = null;
        this.currentUrl = null;
        this.oldResultSearch = false;
        this.paginationSearch = 1;
    }
    removeFromParams(key, value, multipleChoice = true) {
        if (multipleChoice) {
            const index = this.params[key].indexOf(value);
            this.params[key].splice(index, 1);
        }
        else {
            this.params[key] = [];
        }
        return this.params;
    }
    addToParams(key, value, multipleChoice = true) {
        if (value === '') {
            return this.removeFromParams(key, value, multipleChoice);
        }
        else {
            switch (key) {
                case 'destination':
                case 'depart':
                    // TODO 
                    break;
                case 'date_depart':
                    value = this.convertDate(value.value);
                    // TODO 
                    break;
                case 'budget':
                    // if (value === 'min') console.log(this.minBudget)
                    // else console.log(this.maxBudget)
                    break;
                default:
                    break;
            }
            if (multipleChoice)
                this.params[key].push(value);
            else
                this.params[key] = [value];
            return this.params;
        }
    }
    resetParams() {
        this.params = {
            date_depart: [], souplesse: [], depart: [], destination: [], type: [], budget: [], departement: [], region: [],
            regioncp: [], codepostal: [], iata_destination: [], maxresult: [], regime: [], nuits: [], motcle: [], titre: [],
            promo: [], promo_only: [], date_min: [], date_max: []
        };
    }
    convertDate(date) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        let d = new Date(date);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
    }
    search(params) {
        return this.http.post(this.url + `api/venditravels/search`, params);
    }
    getProduct(id) {
        return this.http.get(this.url + `api/venditravels/getProduct/${id}`);
    }
    critereToLabel(critere) {
        // console.log('critere : ', critere);
        // console.log('paramslabel[critere] : ', this.paramslabel[critere]);
        if (this.paramslabel[critere])
            return this.paramslabel[critere];
        else
            return false;
    }
    getLinkDevis(product) {
        const link = 'https://www.speedresa.com/moteurs/sra2/devis.php?';
        const agence = '&AGENCE=V30';
        return `${link}villeDepart=${product.from.code}&dateDepart=${product.date}&duree=${product.duration.night}&IDvoyage=${product.id}${agence}`;
    }
    getOffers() {
        return this.http.get(this.url + `api/venditravels/offers`);
    }
    subscribeResetSearch(callback) {
        return this._resetSearchEventBus.subscribe(callback);
    }
    subscribeSideNavMenu(callback) {
        return this._sideNavMenuEventBus.subscribe(callback);
    }
    activeSideNavMenu() {
        this._sideNavMenuEventBus.publish('');
    }
    resetSearch(key = '', value = '') {
        this.params = {
            date_depart: [], souplesse: [], depart: [], destination: [], type: [], budget: [], departement: [], region: [],
            regioncp: [], codepostal: [], iata_destination: [], maxresult: [], regime: [], nuits: [], motcle: [], titre: [],
            promo: [], promo_only: [], date_min: [], date_max: []
        };
        this.tmpParams = {
            minBudget: 0, maxBudget: 5000, date_depart: ''
        };
        if (key && value)
            this.params[key] = [String(value)];
        if (this.router.url == '/' || this.router.url == '/search')
            this._resetSearchEventBus.publish('');
        else
            this.router.navigate(['/search']);
    }
    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }
    sendInfosRequest(formInfos) {
        return this.http.post(this.url + `api/venditravels/infos-request`, formInfos);
    }
}
VendiTravelsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VendiTravelsService_Factory() { return new VendiTravelsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: VendiTravelsService, providedIn: "root" });
