<div style="width: 90%; margin: 20px auto;">
    <h3>Informatique et Libertés</h3>
    <p>
        Ces informations que nous collectons, nous permettent ainsi qu'à nos partenaires de traiter et d'exécuter votre
        commande.
        <br>Conformément aux dispositions de la loi 78-17 du 6 Janvier 1978 dite Informatique et Libertés, vous disposez
        d'un droit d'accès, de modification, de rectification et de suppression des données vous concernant.
    </p>

    <h3>Déclaration CNIL :</h3>
    <p>
    </p>

    <h3>Responsable de la publication :</h3>
    <p>
        speedMedia </p>

    <h3>Hébergement et conception :</h3>
    <p>
        SpeedMedia Services
        <br>BAT. Gate 73
        <br>73 ter, rue Francis de Pressensé
        <br>69100 Villeurbanne
    </p>

    <h3>Droits d'auteur :</h3>
    <p>
        "La société <b>Vendi travels</b> est titulaire de l'intégralité des droits d'auteur sur le contenu du site et
        ses marques, logos, noms de domaine et tous signes distinctifs afférents sont considérés comme des oeuvres de
        l'esprit sur lesquels la société
        <b>Vendi travels</b> détient tous droits de propriété intellectuelle et commerciale.
        <br>Conformément aux dispositions du Code de la propriété intellectuelle et des traités et accords
        internationaux, toute reproduction, divulgation, distribution, représentation, traduction, diffusion,
        modification, transcription, partielle ou totale, quelque soit le support considéré et quelque soit le procédé
        utilisé pour un usage autre que celui privé est interdit sans l'autorisation préalable et expresse de la société
        <b>Vendi travels</b>.
        <br>Toute contravention aux dispositions du présent article est constitutive du délit de contrefaçon et engage à
        ce titre la responsabilité civile et pénale de son auteur.La loi n° 98-536 du 1er juillet 1998 relative aux
        bases de données n'accorde par ailleurs aucune exception de copie privée."
    </p>

    <h3>Politique liée au démarchage téléphonique :</h3>
    <p>
        Nous vous informons de votre droit d'inscription à la liste d'opposition pour le démarchage téléphonique et vous
        suggérons de vous inscrire sur Bloctel.
        Bloctel est la liste d'opposition au démarchage téléphonique sur laquelle tout consommateur peut s'inscrire
        gratuitement afin de ne plus être démarché téléphoniquement par un professionnel avec lequel il n'a pas de
        relation contractuelle en cours, conformément à la loi n°2014-344 du 17 mars 2014 relative à la consommation.
        La loi précise qu'il est interdit à tout professionnel, directement ou par l'intermédiaire d'un tiers agissant
        pour son compte, de démarcher téléphoniquement un consommateur inscrit sur cette liste, à l'exception des cas
        énumérés par la loi.
        <br>Pour plus d'informations : <a href="http://www.bloctel.gouv.fr/">http://www.bloctel.gouv.fr/</a>
        <br>Pour vous inscrire : <a href="https://conso.bloctel.fr/">https://conso.bloctel.fr/</a>
    </p>
</div>