import { Injectable, OnDestroy } from "@angular/core";
// import { Promise } from 'es6-promise';
import * as _ from "lodash";
import { Subscription } from "rxjs";


import { EventBus } from "../services/utils/models/event-bus.model";
/* import {LoggerService}    from "./logger.service"; */


@Injectable()
export class LoadingService {

  public static GLOBAL_CHANNEL = "GLOBAL";

  private _loadingStartEventBus: EventBus<([string, string])> = new EventBus<([string, string])>();
  private _loadingChangePercentEventBus: EventBus<([string, number])> = new EventBus<([string, number])>();
  private _loadingStopEventBus: EventBus<string> = new EventBus<string>();
  private _counter: [string, number][] = [];

  constructor(/* private _logger : LoggerService */) { }

  public start() {
    this._handleStartLoading(LoadingService.GLOBAL_CHANNEL, "");
  }

  public startWithChannel(channel: string) {
    console.log('startLoading - ', channel)
    this._handleStartLoading(channel, "");
  }

  public startWithChannels(channels: [string]) {
    channels.map(c => (c === "") ? LoadingService.GLOBAL_CHANNEL : c)
      .forEach(c => this._handleStartLoading(c, ""));
  }

  public startWithChannelAndMessage(channel: string, message: string) {
    this._handleStartLoading(channel, message);
  }

  public changePercent(channel: string, percent: number) {
    if (percent < 100) {
      this._handleChangePercent(channel, percent);
    } else {
      this._handleStopLoading(channel);
    }
  }

  public stop() {
    this._handleStopLoading(LoadingService.GLOBAL_CHANNEL);
  }

  public stopAll() {
    this._counter.forEach(c => {
      c[1] = 0;
      this._handleStopLoading(c[0]);
    });
  }

  public stopWithChannel(channel: string) {
    console.log('stopLoading - ', channel)
    this._handleStopLoading(channel);
  }

  public stopWithChannels(channels: [string]) {
    channels.map(c => (c === "") ? LoadingService.GLOBAL_CHANNEL : c)
      .forEach(c => this._handleStopLoading(c));
  }

  public checkIfLoading(channel: string): boolean {
    let c = (channel === "") ? LoadingService.GLOBAL_CHANNEL : channel;

    let item = _.find(this._counter, (e) => e[0] === c);
    return (!item) ? false : true;
  }

  public subscribeToStartLoading(callback: (s: [string, string]) => void): Subscription {
    return this._loadingStartEventBus.subscribe(callback);
    
  }

  public subscribeToChangePercent(callback: (p: [string, number]) => void): Subscription {
    return this._loadingChangePercentEventBus.subscribe(callback);
  }

  public subscribeToStopLoading(callback: (p: string) => void): Subscription {
    return this._loadingStopEventBus.subscribe(callback);
  }

  private _handleStartLoading(channel: string, message: string) {
    let itemCounter = _.find(this._counter, (e) => e[0] === channel);
    if (!itemCounter) {
      this._counter.push([channel, 1]);
      this._loadingStartEventBus.publish([channel, message]);
    } else {
      itemCounter[1] += 1;
    }
  }

  private _handleChangePercent(channel: string, percent: number) {
    let itemCounter = _.find(this._counter, (e) => e[0] === channel);
    if (!itemCounter) {
      //this._logger.warn("Try to change the percentage of a loading that isn't started");
      //console.log("Try to change the percentage of a loading that isn't started")
    } else {
      this._loadingChangePercentEventBus.publish([channel, percent])
    }
  }

  private _handleStopLoading(channel: string) {
    let item = _.find(this._counter, c => c[0] === channel);
    if (!item) {
      //this._logger.warn("Try to stop a loading that isn't started: " + channel);
      //console.log("Try to stop a loading that isn't started: " + channel)
    } else {
      item[1] -= 1;
      if (item[1] <= 0) {
        let index = _.indexOf(this._counter, item);
        this._counter.splice(index, 1);

        this._loadingStopEventBus.publish(channel);
      }
    }
  }

}