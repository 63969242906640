<div style="width: 90%; margin: 20px auto;">
    <h2 class="line text-center">
        <span>
            CONDITIONS GÉNÉRALES DE VENTE </span>
    </h2>

    <div class="row">
        <div class="small-12 columns">
            <p style="text-align: justify;">La combinaison de services de voyage qui vous est proposée est un forfait au
                sens de la directive (UE) 2015/2302 et de l'article L.211-2 II du code du tourisme. Vous bénéficierez
                donc de tous les droits octroyés par l'Union européenne applicables aux forfaits, tels que transposés
                dans le code du tourisme.<br>
                Les entreprises qui vous proposent ces offres seront entièrement responsables de la bonne exécution du
                forfait dans son ensemble. En outre, comme l'exige la loi, ces entreprises doivent disposer d'une
                protection afin de rembourser vos paiements et, si le transport est compris dans le forfait, d'assurer
                votre rapatriement au cas où elles deviendraient insolvables.</p>

            <p style="text-align: justify;">Pour plus d'informations sur les droits essentiels au titre de la directive
                (UE) 2015/2302 veuillez cliquer sur le lien suivant (ou copier/coller cette adresse dans votre
                navigateur) :&nbsp;<a
                    href="https://www.legifrance.gouv.fr/eli/decret/2017/12/29/2017-1871/jo">https://www.legifrance.gouv.fr/eli/decret/2017/12/29/2017-1871/jo</a>
            </p>

            <p style="text-align: justify;">Décret n° 2017-1871 du 29 décembre 2017 pris pour l'application de
                l'ordonnance n° 2017-1717 du 20 décembre 2017 portant transposition de la directive (UE) 2015/2302 du
                Parlement européen et du Conseil du 25 novembre 2015 relative aux voyages à forfait et aux prestations
                de voyage liées.</p>

            <p style="text-align: justify;">Section 2&nbsp;<br>
                Contrat de vente de voyages et de séjours<br>
                <br>
                <b>Art. R. 211-3. -</b>&nbsp;Toute offre et toute vente des prestations mentionnées à l'article L. 211-1
                donnent lieu à la remise de documents appropriés qui répondent aux règles définies par la présente
                section.</p>

            <p style="text-align: justify;"><b>Art. R. 211-3-1. -</b>&nbsp;L'échange d'informations pré contractuelles
                ou la mise à disposition des conditions contractuelles est effectué par écrit. Ils peuvent se faire par
                voie électronique. Sont mentionnés le nom ou la raison sociale et l'adresse de l'organisateur ou du
                détaillant ainsi que l'indication de son immatriculation au registre prévu à l'article L. 141-3 ou, le
                cas échéant, le nom, l'adresse et l'indication de l'immatriculation de la fédération ou de l'union
                mentionnées au deuxième alinéa de l'article R. 211-2.</p>

            <p style="text-align: justify;"><b>Art. L. 211-1.</b>&nbsp;<br>
                <b>I.</b>- Le présent chapitre s'applique aux personnes physiques ou morales qui élaborent et vendent ou
                offrent à la vente dans le cadre de leur activité commerciale, industrielle, artisanale ou libérale :1°
                Des forfaits touristiques ;2° Des services de voyage portant sur le transport, le logement, la location
                d'un véhicule ou d'autres services de voyage qu'elles ne produisent pas elles-mêmes.Il s'applique
                également aux professionnels qui facilitent aux voyageurs l'achat de prestations de voyage liées au sens
                de l'article L. 211-2.<br>
                <b>II.</b>- Les organismes locaux de tourisme bénéficiant du soutien de l'État, des collectivités
                territoriales ou de leurs groupements peuvent se livrer ou apporter leur concours, dans l'intérêt
                général, à des opérations mentionnées au I, dès lors que celles-ci permettent de faciliter l'accueil ou
                d'améliorer les conditions de séjour des touristes dans leur zone géographique d'intervention.<br>
                <b>III.</b>- Le présent chapitre s'applique aux personnes physiques ou morales qui émettent des bons ou
                coffrets permettant d'acquitter le prix de l'une des prestations mentionnées au I. Il ne s'applique pas
                aux personnes physiques ou morales qui n'effectuent que la vente de ces bons ou coffrets.<br>
                <b>IV.</b>- Le présent chapitre n'est pas applicable aux personnes qui ne proposent des forfaits, des
                services de voyage ou ne facilitent la conclusion de prestations de voyage liées qu'à titre occasionnel,
                dans un but non lucratif et pour un groupe limité de voyageurs uniquement.<br>
                <b>V.</b>- Le présent chapitre ne s'applique pas aux personnes suivantes, sauf en ce qui concerne
                l'organisation, la vente ou l'offre à la vente de forfaits ou lorsqu'elles facilitent l'achat de
                prestations de voyage liées :1° Aux personnes physiques ou morales qui n'effectuent que la délivrance de
                titres de transport terrestre pour le compte d'un ou de plusieurs transporteurs de voyageurs ;2° Aux
                transporteurs aériens qui n'effectuent que la délivrance de titres de transport aérien ou de titres de
                transports consécutifs incluant un parcours de transport aérien et, à titre accessoire, un ou plusieurs
                parcours de transport terrestre assurés par un ou plusieurs transporteurs de voyageurs ;3° Aux
                transporteurs ferroviaires qui n'effectuent que la délivrance de titres de transport ferroviaire ou de
                titres de transports consécutifs incluant un parcours de transport ferroviaire et, à titre accessoire,
                d'autres parcours de transport terrestre ou aérien assurés par un ou plusieurs transporteurs de
                voyageurs ;4° Aux personnes physiques ou morales titulaires d'une carte professionnelle délivrée en
                application de la loi n° 70-9 du 2 janvier 1970 réglementant les conditions d'exercice des activités
                relatives à certaines opérations portant sur les immeubles et les fonds de commerce, lorsqu'elles ne
                réalisent les opérations mentionnées au 2° du I qu'à titre accessoire. Ces personnes doivent souscrire,
                pour la réalisation de ces opérations, une assurance garantissant les conséquences pécuniaires de la
                responsabilité professionnelle et une garantie financière permettant le remboursement des fonds déposés.
            </p>

            <p style="text-align: justify;"><b>Art. R. 211-4. -</b>&nbsp;Préalablement à la conclusion du contrat,
                l'organisateur ou le détaillant doit communiquer au voyageur les informations suivantes :1° Les
                caractéristiques principales des services de voyage :&nbsp;<br>
                a) La ou les destinations, l'itinéraire et les périodes de séjour, avec les dates et, lorsque le
                logement est compris, le nombre de nuitées comprises ;&nbsp;<br>
                b) Les moyens, caractéristiques et catégories de transport, les lieux, dates et heures de départ et de
                retour, la durée et le lieu des escales et des correspondances. Lorsque l'heure exacte n'est pas encore
                fixée, l'organisateur ou le détaillant informe le voyageur de l'heure approximative du départ et du
                retour ;&nbsp;<br>
                c) La situation, les principales caractéristiques et, s'il y a lieu, la catégorie touristique de
                l'hébergement en vertu des règles du pays de destination ;&nbsp;<br>
                d) Les repas fournis ;&nbsp;<br>
                e) Les visites, les excursions ou les autres services compris dans le prix total convenu pour le contrat
                ;&nbsp;<br>
                f) Lorsque cela ne ressort pas du contexte, si les services de voyage éventuels seront fournis au
                voyageur en tant que membre d'un groupe et, dans ce cas, si possible, la taille approximative du groupe
                ;&nbsp;<br>
                g) Lorsque le bénéfice d'autres services touristiques fournis au voyageur repose sur une communication
                verbale efficace, la langue dans laquelle ces services seront fournis ;&nbsp;<br>
                h) Des informations sur le fait de savoir si le voyage ou le séjour de vacances est, d'une manière
                générale, adapté aux personnes à mobilité réduite et, à la demande du voyageur, des informations
                précises sur l'adéquation du voyage ou du séjour de vacances aux besoins du voyageur ;2° La dénomination
                sociale et l'adresse géographique de l'organisateur et du détaillant, ainsi que leurs coordonnées
                téléphoniques et, s'il y a lieu, électroniques ;3° Le prix total incluant les taxes et, s'il y a lieu,
                tous les frais, redevances ou autres coûts supplémentaires, ou, quand ceux-ci ne peuvent être
                raisonnablement calculés avant la conclusion du contrat, une indication du type de coûts additionnels
                que le voyageur peut encore avoir à supporter ;4° Les modalités de paiement, y compris le montant ou le
                pourcentage du prix à verser à titre d'acompte et le calendrier pour le paiement du solde, ou les
                garanties financières à verser ou à fournir par le voyageur ;5° Le nombre minimal de personnes requis
                pour la réalisation du voyage ou du séjour et la date limite mentionnée au III de l'article L. 211-14
                précédant le début du voyage ou du séjour pour une éventuelle résolution du contrat au cas où ce nombre
                ne serait pas atteint ;6° Des informations d'ordre général concernant les conditions applicables en
                matière de passeports et de visas, y compris la durée approximative d'obtention des visas, ainsi que des
                renseignements sur les formalités sanitaires, du pays de destination ;7° Une mention indiquant que le
                voyageur peut résoudre le contrat à tout moment avant le début du voyage ou du séjour, moyennant le
                paiement de frais de résolution appropriés ou, le cas échéant, de frais de résolution standard réclamés
                par l'organisateur ou le détaillant, conformément au I de l'article L. 211-14 ;8° Des informations sur
                les assurances obligatoires ou facultatives couvrant les frais de résolution du contrat par le voyageur
                ou sur le coût d'une assistance, couvrant le rapatriement, en cas d'accident, de maladie ou de décès.En
                ce qui concerne les forfaits définis au e du 2° du A du II de l'article L. 211-2, l'organisateur ou le
                détaillant et le professionnel auxquels les données sont transmises veillent à ce que chacun d'eux
                fournisse, avant que le voyageur ne soit lié par un contrat, les informations énumérées au présent
                article dans la mesure où celles-ci sont pertinentes pour les services de voyage qu'ils offrent.<br>
                Le formulaire par lequel les informations énumérées au présent article sont portées à la connaissance du
                voyageur est fixé par arrêté conjoint du ministre chargé du tourisme et du ministre chargé de l'économie
                et des finances. Cet arrêté précise les informations minimales à porter à la connaissance du voyageur
                lorsque le contrat est conclu par téléphone.</p>

            <p style="text-align: justify;"><b>Art. L. 211-2.</b>&nbsp;<br>
                <b>I.</b>- Constitue un service de voyage :<br>
                1° Le transport de passagers ;2° L'hébergement qui ne fait pas partie intégrante du transport de
                passagers et qui n'a pas un objectif résidentiel ;3° La location de voitures particulières, d'autres
                véhicules de catégorie M au sens de l'article R. 311-1 du code de la route ayant une vitesse maximale
                par construction supérieure à 25 km/ h ou de motocyclettes au sens de l'article R. 311-1 du code de la
                route dont la conduite nécessite la possession d'un permis de conduire de catégorie A conformément aux
                dispositions de l'article R. 221-4 de ce même code ;4° Tout autre service touristique qui ne fait pas
                partie intégrante d'un service de voyage au sens des 1°, 2° ou 3°.<b>II.</b>- A.- Constitue un forfait
                touristique la combinaison d'au moins deux types différents de services de voyage aux fins du même
                voyage ou séjour de vacances, dépassant vingt-quatre heures ou incluant une nuitée, si :1° Ces services
                sont combinés par un seul professionnel, y compris à la demande du voyageur ou conformément à son choix,
                avant qu'un contrat unique incluant tous ces services ne soit conclu ;2° Indépendamment de l'éventuelle
                conclusion de contrats séparés avec des prestataires de services de voyage individuels, ces services
                sont :<br>
                a) Soit achetés auprès d'un seul point de vente et choisis avant que le voyageur n'accepte de payer
                ;&nbsp;<br>
                b) Soit proposés, vendus ou facturés à un prix tout compris ou à un prix total ;&nbsp;<br>
                c) Soit annoncés ou vendus sous la dénomination de "forfait" ou sous une dénomination similaire
                ;&nbsp;<br>
                d) Soit combinés après la conclusion d'un contrat par lequel un professionnel autorise le voyageur à
                choisir parmi une sélection de différents types de services de voyage ;&nbsp;<br>
                e) Soit achetés auprès de professionnels distincts grâce à des procédures de réservation en ligne liées,
                lorsque le nom du voyageur, les modalités de paiement et l'adresse électronique sont transmis par le
                professionnel avec lequel le premier contrat est conclu à un ou plusieurs autres professionnels et
                lorsqu'un contrat avec ce ou ces derniers est conclu au plus tard vingt-quatre heures après la
                confirmation de la réservation du premier service de voyage.B.- Les combinaisons de services de voyage
                dans lesquelles un seul des types de service de voyage mentionnés au 1°, au 2°, ou au 3° du I est
                combiné à un ou plusieurs des services touristiques mentionnés au 4° du I ne constituent pas un forfait
                si ces derniers services :1° Ne représentent pas une part significative de la valeur de la combinaison,
                ne sont pas annoncés comme étant une caractéristique essentielle de la combinaison ou ne constituent pas
                d'une manière ou d'une autre une telle caractéristique, ou2° Sont choisis et achetés uniquement après
                que l'exécution d'un service de voyage mentionné au 1°, au 2° ou au 3° du I a commencé.<b>III.</b>-
                Constitue une prestation de voyage liée la combinaison d'au moins deux types différents de services de
                voyage achetés aux fins du même voyage ou séjour de vacances, couvrant au moins vingt-quatre heures ou
                une nuitée, ne constituant pas un forfait et entraînant la conclusion de contrats séparés avec des
                prestataires de services de voyage individuels, si un professionnel facilite :1° A l'occasion d'une
                seule visite à son point de vente ou d'une seule prise de contact avec celui-ci, le choix séparé et le
                paiement séparé de chaque service de voyage par les voyageurs ou2° D'une manière ciblée, l'achat d'au
                moins un service de voyage supplémentaire auprès d'un autre professionnel lorsque le contrat avec cet
                autre professionnel est conclu au plus tard vingt-quatre heures après la confirmation de la réservation
                du premier service de voyage.Lorsqu'il est acheté un seul des types de service de voyage mentionnés au
                1°, au 2° ou au 3° du I et un ou plusieurs des services touristiques mentionnés au 4° du I, ceux-ci ne
                constituent pas une prestation de voyage liée si ces derniers services ne représentent pas une part
                significative de la valeur combinée des services et ne sont pas annoncés comme étant une caractéristique
                essentielle du voyage ou séjour de vacances ou ne constituent pas d'une manière ou d'une autre une telle
                caractéristique.<br>
                <b>IV.</b>- Pour l'application du présent chapitre, le voyageur est une personne cherchant à conclure un
                contrat relevant du champ d'application du présent chapitre ou ayant le droit de voyager sur la base
                d'un tel contrat déjà conclu.<br>
                Un professionnel est une personne physique ou morale, qu'elle soit publique ou privée, qui agit, y
                compris par l'intermédiaire d'une autre personne agissant en son nom ou pour son compte, aux fins qui
                entrent dans le cadre de son activité commerciale, industrielle, artisanale ou libérale en ce qui
                concerne des contrats relevant du présent chapitre, qu'elle agisse en qualité d'organisateur, de
                détaillant, de professionnel facilitant une prestation de voyage liée ou de prestataire d'un service de
                voyage.<br>
                Un organisateur est un professionnel qui élabore des forfaits touristiques et les vend ou les offre à la
                vente, directement ou par l'intermédiaire d'un autre professionnel ou encore conjointement avec un autre
                professionnel, ou un professionnel qui transmet les données du voyageur à un autre professionnel
                conformément au e du 2° du A du II.<br>
                Un détaillant est un professionnel autre que l'organisateur, qui vend ou offre à la vente des forfaits
                élaborés par un organisateur ou des services de voyage assurés par un autre professionnel.<br>
                <b>V.</b>- Pour l'application du présent chapitre, on entend par :1° Point de vente : tout site
                commercial, qu'il soit meuble ou immeuble, ou un site internet commercial ou une structure de vente en
                ligne similaire, y compris lorsque des sites internet commerciaux ou des structures de vente en ligne
                sont présentés aux voyageurs comme une structure unique, y compris un service téléphonique ;2° Support
                durable : tout instrument permettant au voyageur ou au professionnel de stocker des informations qui lui
                sont adressées personnellement d'une manière permettant de s'y reporter ultérieurement pendant un laps
                de temps adapté aux fins auxquelles les informations sont destinées et qui permet la reproduction à
                l'identique des informations stockées ;3° Circonstances exceptionnelles et inévitables : une situation
                échappant au contrôle de la partie qui invoque cette situation et dont les conséquences n'auraient pu
                être évitées même si toutes les mesures raisonnables avaient été prises.</p>

            <p style="text-align: justify;"><b>Art. R. 211-5. -</b>&nbsp;Les informations mentionnées aux 1°, 3°, 4°, 5°
                et 7° de l'article R. 211-4 communiquées au voyageur font partie du contrat et ne peuvent être modifiées
                que dans les conditions définies à l'article L. 211-9</p>

            <p style="text-align: justify;"><b>Art. L. 211-9. -</b>&nbsp;Les informations pré contractuelles
                communiquées au voyageur font partie intégrante du contrat et ne peuvent pas être modifiées, sauf si les
                parties contractantes en conviennent expressément autrement. L'organisateur ou le détaillant communique
                toutes les modifications relatives aux informations pré contractuelles au voyageur, de façon claire,
                compréhensible et apparente, avant la conclusion du contrat.</p>

            <p style="text-align: justify;"><b>Art. R. 211-6. -</b>&nbsp;Le contrat doit comporter, outre les
                informations définies à l'article R. 211-4, les informations suivantes :1° Les exigences particulières
                du voyageur que l'organisateur ou le détaillant a acceptées ;2° Une mention indiquant que l'organisateur
                ainsi que le détaillant sont responsables de la bonne exécution de tous les services de voyage compris
                dans le contrat conformément à l'article L. 211-16 et qu'ils sont tenus d'apporter une aide au voyageur
                s'il est en difficulté, conformément à l'article L. 211-17-1 ;3° Le nom de l'entité chargée de la
                protection contre l'insolvabilité et ses coordonnées, dont son adresse géographique ;4° Le nom,
                l'adresse, le numéro de téléphone, l'adresse électronique et, le cas échéant, le numéro de télécopieur
                du représentant local de l'organisateur ou du détaillant, d'un point de contact ou d'un autre service
                par l'intermédiaire duquel le voyageur peut contacter rapidement l'organisateur ou le détaillant et
                communiquer avec lui de manière efficace, demander une aide si le voyageur est en difficulté ou se
                plaindre de toute non-conformité constatée lors de l'exécution du voyage ou du séjour ;5° Une mention
                indiquant que le voyageur est tenu de communiquer toute non-conformité qu'il constate lors de
                l'exécution du voyage ou du séjour conformément au II de l'article L. 211-16 ;6° Lorsque des mineurs,
                non accompagnés par un parent ou une autre personne autorisée, voyagent sur la base d'un contrat
                comprenant un hébergement, des informations permettant d'établir un contact direct avec le mineur ou la
                personne responsable du mineur sur le lieu de séjour du mineur ;7° Des informations sur les procédures
                internes de traitement des plaintes disponibles et sur les mécanismes de règlement extrajudiciaire des
                litiges et, s'il y a lieu, sur l'entité dont relève le professionnel et sur la plateforme de règlement
                en ligne des litiges prévue par le règlement (UE) n° 524/2013 du Parlement européen et du Conseil ;8°
                Des informations sur le droit du voyageur de céder le contrat à un autre voyageur conformément à
                l'article L. 211-11.En ce qui concerne les forfaits définis au e du 2° du A du II de l'article L. 211-2,
                le professionnel auquel les données sont transmises informe l'organisateur ou le détaillant de la
                conclusion du contrat donnant lieu à la création d'un forfait. Le professionnel lui fournit les
                informations nécessaires pour lui permettre de s'acquitter de ses obligations en tant qu'organisateur.
                Dès que l'organisateur ou le détaillant est informé de la création d'un forfait, il fournit au voyageur,
                sur un support durable, les informations mentionnées aux 1° à 8°.</p>

            <p style="text-align: justify;"><b>Art. R. 211-7. -</b>&nbsp;Le voyageur peut céder son contrat à un
                cessionnaire qui remplit les mêmes conditions que lui pour effectuer le voyage ou le séjour, tant que ce
                contrat n'a produit aucun effet.&nbsp;<br>
                Sauf stipulation plus favorable au cédant, celui-ci est tenu d'informer l'organisateur ou le détaillant
                de sa décision par tout moyen permettant d'en obtenir un accusé de réception au plus tard sept jours
                avant le début du voyage. Cette cession n'est soumise, en aucun cas, à une autorisation préalable de
                l'organisateur ou du détaillant.</p>

            <p style="text-align: justify;"><b>Art. R. 211-8. -</b>&nbsp;Lorsque le contrat comporte une possibilité
                expresse de révision du prix, dans les limites prévues à l'article L. 211-12, il mentionne les modalités
                précises de calcul, tant à la hausse qu'à la baisse, des variations des prix, notamment le montant des
                frais de transport et taxes y afférentes, la ou les devises qui peuvent avoir une incidence sur le prix
                du voyage ou du séjour, la part du prix à laquelle s'applique la variation, ainsi que le cours de la ou
                des devises retenu comme référence lors de l'établissement du prix figurant au contrat.&nbsp;<br>
                En cas de diminution du prix, l'organisateur ou le détaillant a le droit de déduire ses dépenses
                administratives réelles du remboursement dû au voyageur. A la demande du voyageur, l'organisateur ou le
                détaillant apporte la preuve de ces dépenses administratives.</p>

            <p style="text-align: justify;"><b>Art. R. 211-9. -</b>&nbsp;Lorsque, avant le départ du voyageur,
                l'organisateur ou le détaillant se trouve contraint d'apporter une modification à l'un des éléments
                essentiels du contrat, s'il ne peut pas satisfaire aux exigences particulières mentionnées au 1° de
                l'article R. 211-6, ou en cas de hausse du prix supérieure à 8 %, il informe le voyageur dans les
                meilleurs délais, d'une manière claire, compréhensible et apparente, sur un support durable :1° Des
                modifications proposées et, s'il y a lieu, de leurs répercussions sur le prix du voyage ou du séjour ;2°
                Du délai raisonnable dans lequel le voyageur doit communiquer à l'organisateur ou au détaillant la
                décision qu'il prend ;3° Des conséquences de l'absence de réponse du voyageur dans le délai fixé ;4°
                S'il y a lieu, de l'autre prestation proposée, ainsi que de son prix.Lorsque les modifications du
                contrat ou la prestation de substitution entraînent une baisse de qualité du voyage ou du séjour ou de
                son coût, le voyageur a droit à une réduction de prix adéquate.&nbsp;<br>
                Si le contrat est résolu et le voyageur n'accepte pas d'autre prestation, l'organisateur ou le
                détaillant rembourse tous les paiements effectués par le voyageur ou en son nom dans les meilleurs
                délais et en tout état de cause au plus tard quatorze jours après la résolution du contrat, sans
                préjudice d'un dédommagement en application de l'article L. 211-17.</p>

            <p style="text-align: justify;"><b>Art. R. 211-10. -</b>&nbsp;L'organisateur ou le détaillant procède aux
                remboursements requis en vertu des II et III de l'article L. 211-14 ou, au titre du I de l'article L.
                211-14, rembourse tous les paiements effectués par le voyageur ou en son nom moins les frais de
                résolution appropriés. Ces remboursements au profit du voyageur sont effectués dans les meilleurs délais
                et en tout état de cause dans les quatorze jours au plus tard après la résolution du contrat.&nbsp;<br>
                Dans le cas prévu au III de l'article L. 211-14, l'indemnisation supplémentaire que le voyageur est
                susceptible de recevoir est au moins égale à la pénalité qu'il aurait supportée si l'annulation était
                intervenue de son fait à cette date.</p>

            <p style="text-align: justify;"><b>Art. R. 211-11. -</b>&nbsp;L'aide due par l'organisateur ou le détaillant
                en application de l'article L. 211-17-1 consiste notamment :1° A fournir des informations utiles sur les
                services de santé, les autorités locales et l'assistance consulaire ;2° A aider le voyageur à effectuer
                des communications longue distance et à trouver d'autres prestations de voyage.L'organisateur ou le
                détaillant est en droit de facturer un prix raisonnable pour cette aide si cette difficulté est causée
                de façon intentionnelle par le voyageur ou par sa négligence. Le prix facturé ne dépasse en aucun cas
                les coûts réels supportés par l'organisateur ou le détaillant.</p>

            <p style="text-align: justify;">&nbsp;</p>

            <h3 style="text-align: justify;">L'ANNULATION PAR LE VOYAGEUR</h3>

            <p style="text-align: justify;">Les voyageurs peuvent résoudre le contrat sans payer de frais de résolution
                avant le début du forfait en cas de circonstances exceptionnelles, par exemple s'il existe des problèmes
                graves pour la sécurité au lieu de destination qui sont susceptibles d'affecter le forfait. En outre,
                les voyageurs peuvent, à tout moment avant le début du forfait, résoudre le contrat moyennant le
                paiement de frais de résolution appropriés et justifiables.<br>
                <br>
                Conformément à l'article L221-28 du Code de la consommation, le présent contrat n'est pas soumis au
                droit de rétractation.<br>
                <br>
                Toutefois, le voyageur peut résoudre le contrat à tout moment avant le début du voyage et s'acquittera
                des frais fixés soit selon un barème d'annulation (sur le prix total du voyage ) fixé par l'agence de
                voyage et renseigné dans les conditions particulières de vente, soit selon un calcul des frais réels
                d'annulation supportés par l'organisateur et/ou l'agent de voyage.</p>

            <p style="text-align: justify;">&nbsp;</p>

            <p style="text-align: justify;"><span style="color:#008080;"><strong><span style="font-size:14px;">MOYENS DE
                            PAIEMENT</span></strong></span></p>

            <p style="text-align: justify;">Les paiements s’effectuent par tous moyens acceptés par le site, à savoir :
            </p>

            <p style="text-align: justify;">- Carte Bancaire (Visa, Mastercard)</p>

            <p style="text-align: justify;">- Chèques Vacances ANCV</p>

            <p style="text-align: justify;">A noter toutefois qu’aucun n’acompte ne peut être réglé par Chèques-Vacances
                ANCV, par conséquent, ces derniers devront être versés par carte bancaire avant la date indiquée.</p>

            <p style="text-align: justify;">Les Chèques-Vacances ANCV ne peuvent être acceptés qu’à partir d’un montant
                total dossier d’une valeur égale ou supérieure à 200 €. De plus, ce moyen de paiement ne peut excéder
                30% du solde total du dossier.</p>

            <p style="text-align: justify;">Le paiement par Chèques-Vacances ANCV n’est possible que pour les voyages à
                destination de l’Union Européenne (DOM compris). Il n’est rendu aucune monnaie et il n’est établi aucun
                avoir, en cas de règlement par Chèques-Vacances ANCV. Ces derniers ne sont pas remboursables.</p>

            <p style="text-align: justify;">&nbsp;</p>

            <h3 style="text-align: justify;">L'ANNULATION PAR L'AGENCE DE VOYAGE OU L'ORGANISATEUR</h3>

            <p style="text-align: justify;">Le voyage peut être annulé par l'organisateur ou l'agence si le nombre
                minimum de participants renseignés dans les conditions particulières de vente n'est pas inscrit :<br>
                20 jours avant la date de départ, pour les voyages dont la durée dépasse 6 jours.&nbsp;<br>
                7 jours avant la date de départ, pour les voyages dont la durée est de 2 à 6 jours.&nbsp;<br>
                48h avant la date de départ, pour les voyages dont la durée est 2 jours.&nbsp;<br>
                Le Voyageur sera alors remboursé de toutes les sommes qu'il aura pu verser mais ne pourra pas prétendre
                à une indemnisation.</p>

            <p style="text-align: justify;">&nbsp;</p>

            <h3 style="text-align: justify;">RESPONSABILITÉ</h3>

            <p style="text-align: justify;">Le détaillant et l'organisateur sont responsables de la bonne exécution des
                services prévus au présent contrat et sont tenus d'apporter de l'aide au voyageur en difficulté. En cas
                de mise en jeu de leur responsabilité de plein droit du fait des prestataires, les limites de
                dédommagement résultant de conventions internationales selon l'article L. 211-17-IV du Code du Tourisme
                trouveront à s'appliquer ; à défaut et sauf préjudice corporels, dommages intentionnels ou causés par
                négligence, les dommages-intérêts éventuels sont limités à trois fois le prix total du voyage ou du
                séjour. Dans la partie mentions légales, vous trouverez les indications concernant le garant financier
                de l'agence de voyage ainsi que son assureur de responsabilité civile.</p>

            <p style="text-align: justify;">&nbsp;</p>

            <h3 style="text-align: justify;">RÉCLAMATION ET MÉDIATION</h3>

            <p style="text-align: justify;">Le voyageur peut saisir le service client de l'agence de toute réclamation,
                à l'adresse indiquée dans les informations pratiques par lettre RAR accompagné(e) de tout justificatif.
                A défaut de réponse satisfaisante dans un délai de 60 jours ou s'il n'est pas satisfait de la réponse
                reçue, le client peut saisir gratuitement le Médiateur du Tourisme et du Voyage, dont les coordonnées et
                modalités de saisine sont disponibles sur le site : www.mtv.travel. Si la vente s'effectue en ligne, le
                voyageur a la possibilité de recourir à la plateforme disponible sur le site&nbsp;<a
                    href="https://webgate.ec.europa.eu/odr">https://webgate.ec.europa.eu/odr</a>&nbsp;pour régler son
                litige.</p>
        </div>
    </div>
</div>