import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { VendiTravelsService } from 'src/app/services/venditravels.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HelperService } from 'src/app/services/helper.service';

const _ = require('lodash');

import listeDestinations from '../../resources/listeDestinations.json';
import airports from '../../resources/airports.json';
import thematiques from '../../resources/thematiques.json';
import { SearchComponentComponent } from 'src/app/global-components/search/search-component.component';
import { MultiselectComponent } from 'src/app/global-components/multiselect/multiselect.component';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { realpath } from 'fs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  @ViewChild(SearchComponentComponent, { read: false, static: false }) searchComponent: SearchComponentComponent


  public listeDestinations = listeDestinations;
  public airports = airports;
  public thematiques = thematiques;
  public lodash = _;
  public tmpRegime: String = '';
  public resultSearch = {};
  public params: any = [];
  public minBudget: Number = 0;
  public maxBudget: Number = 10000;
  public currentPage: number = 1;
  public lastPage: number = 1;
  public loopPagination = []
  public productsDisplay = [];
  public getPreviousSearch: Boolean = false;
  public history;
  public init: Boolean = false;
  public canDisplayIgnoredCriterias: Boolean = false;

  constructor(
    private vt: VendiTravelsService,
    private loadingService: LoadingService,
    private hlp: HelperService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.params = this.vt.params;

    this.search();

  }

  public async search(maxresult = 100, country = '') {

    console.log(
      "previousUrl : ", this.vt.previousUrl, '\n',
      "currentUrl : ", this.vt.currentUrl,
      "init : ", this.init,
      "oldResult : ", this.vt.oldResultSearch
    )

    this.params = this.vt.params

    this.loadingService.startWithChannel('vt_search')
    if (maxresult != 100) this.vt.addToParams('maxresult', '500', false);

    if (this.vt.previousUrl && this.vt.previousUrl.split('/')[1] === "product" && this.vt.oldResultSearch && !this.init) {
      console.log('OLD_result');
      this.resultSearch = this.vt.oldResultSearch;
    } else {
      console.log('NEW_result');
      this.resultSearch = await this.vt.search(this.params).toPromise()
        .catch((error) => {
          this.loadingService.stopWithChannel('vt_search');
          this.hlp.message('Une erreur est survenue, veuillez réessayer ultérieurement.', 'error');
          return error;
        })
      this.vt.oldResultSearch = this.resultSearch;

      this.canTheCriteriaBeDisplayed(_.get(this.resultSearch, 'infos.ignored_criterias', []));

      console.log('resultSearch : ', this.resultSearch);
    }


    _.get(this.resultSearch, 'infos.ignored_criterias', []);

    const ignoredDateDepart = _.get(this.resultSearch, 'infos.ignored_criterias', []).includes('date_depart');
    const ignoredVilleDepart = _.get(this.resultSearch, 'infos.ignored_criterias', []).includes('depart');

    console.log('Encore resultSearch : ', this.resultSearch);


    this.resultSearch['products'] = this.resultSearch['products'].map((prod) => {

      // ====== TODO SET PRICE  ====== //
      let realBestPrice;

      if (!_.isEmpty(this.params.date_depart) && !_.isEmpty(this.params.depart) && !ignoredDateDepart && !ignoredVilleDepart) {

        realBestPrice = (prod.prices.date.find((d) => {
          // console.log(d.date[0])
          return d.date[0] === this.params.date_depart[0].split("/").reverse().join("-");
        }));

        realBestPrice = this.formatBestPrice(realBestPrice);
        _.set(prod, 'realBestPrice', realBestPrice);

      }
      else if (!_.isEmpty(this.params.date_depart) && !ignoredDateDepart) {

        realBestPrice = (prod.prices.date.find((d) => {
          return d.date[0] === this.params.date_depart[0].split("/").reverse().join("-");
        }));

        realBestPrice = this.formatBestPrice(realBestPrice);
        _.set(prod, 'realBestPrice', realBestPrice);

      }
      else if (!_.isEmpty(this.params.depart) && !ignoredVilleDepart) {

        prod.prices.date.forEach((pdd) => {
          if (!_.isNil(pdd.amount[0])) {
            if (_.isNil(realBestPrice) || !realBestPrice.amount[0]) {
              realBestPrice = pdd;
            }
            else if (pdd.amount[0] < realBestPrice.amount[0]) {
              realBestPrice = pdd;
            }
          }
        });

        realBestPrice = this.formatBestPrice(realBestPrice);
        _.set(prod, 'realBestPrice', realBestPrice);

      }
      else {
        realBestPrice = this.formatBestPrice(prod.prices.best[0]);
        _.set(prod, 'realBestPrice', realBestPrice); //default
      }

      return prod;

    });


    if (maxresult != 100) this.vt.removeFromParams('maxresult', '500', false);
    this.loadingService.stopWithChannel('vt_search');

    this.lastPage = parseInt((this.resultSearch['infos']['nbProducts'] / 10).toFixed(0))

    if (this.resultSearch['infos']['nbProducts'] % 10 !== 0 && this.resultSearch['infos']['nbProducts'] > 10) this.lastPage++;

    if (this.lastPage === 0) this.lastPage++;

    this.loopPagination = Array(this.lastPage).fill(1).map((x, i) => i);

    this.currentPage = 1;
    if (this.vt.previousUrl && this.vt.previousUrl.split('/')[1] === "product" && this.vt.oldResultSearch && !this.init) {
      this.currentPage = this.vt.paginationSearch;
    }

    this.paginate(this.currentPage);
    this.init = true;

  }

  formatBestPrice(bestprice) {

    const bestPriceFormatted = {
      amount: _.get(bestprice, 'amount[0]', ''),
      date: _.get(bestprice, 'date[0]', ''),
      from: {
        code: _.get(bestprice, 'from[0].airport[0].ref[0]', ''),
        label: ''
      },
      duration: {
        day: _.get(bestprice, 'duration[0].day[0]'),
        night: _.get(bestprice, 'duration[0].night[0]'),
      }
    };

    return bestPriceFormatted;

  }

  paginate(page) {

   /*  console.log(
      'page : ', page,
      '\nlastpage : ', this.lastPage,
      '\ncurrentPage : ', this.currentPage
    ); */

    if (page >= 0 && page <= this.lastPage) {

      console.log('ICIIII');

      console.log(this.resultSearch['products']);

      this.currentPage = page;
      this.productsDisplay = this.resultSearch['products'].slice((this.currentPage - 1) * 10, this.currentPage * 10);
      this.vt.paginationSearch = this.currentPage;
    }

    console.log('productsDisplay : ', this.productsDisplay);

  }

  canTheCriteriaBeDisplayed(criteresList) {

    const result = criteresList.map((c) => this.critereToLabel(c));
    this.canDisplayIgnoredCriterias = !result.includes(false);

    console.log(result)
    console.log('canDisplayIgnoredCriterias : ', this.canDisplayIgnoredCriterias);

  }

  critereToLabel(critere) {
    return this.vt.critereToLabel(critere)
  }

  getProductsSorted(sortby, direction) {

    let sortFunction;

    if (sortby === 'price') {
      sortFunction = (a, b) => a.prices.best[0].amount[0] - b.prices.best[0].amount[0]
    } else {
      sortFunction = (a, b) => a.duration.night - b.duration.night
    }

    this.resultSearch['products'].sort(sortFunction)

    if (direction === 'desc') this.resultSearch['products'].reverse()

    this.paginate(1)

  }



}