<section>

  <app-loading [channel]="'vt_product'">

    <div class="background" *ngIf="product">

      <div class="first-container">

        <div class="header-product">

          <div>
            <h2 class="title-product" [innerHTML]='product.title'></h2>
          </div>

        </div>

        <div class="container-cards">

          <div class="div-carrousel card" *ngIf="product.photo">

            <ng-container *ngIf="lodash.get(product, 'photo', false)">
              <div class="carousel-product">
                <ngx-slick-carousel class="carousel" [config]="slideConfig">
                  <div ngxSlickItem *ngFor="let background of product.photo" class="slide">

                    <div *ngIf="background" class="div-background-carousel" [ngStyle]="background"></div>

                  </div>
                </ngx-slick-carousel>
              </div>
            </ng-container>
          </div>

          <div class="short-description card">

            <span class="span-destination spanTitleProduct">
              <ng-container *ngIf='product.tos.city'> {{product.tos.city}} </ng-container>
              <ng-container *ngIf='product.tos.city && product.tos.country'> - </ng-container>
              <ng-container *ngIf='product.tos.country'> {{product.tos.country}} </ng-container>
            </span>

            <div class="div-description">
              <p [ngClass]="{'p-description': !showAllDescription, 'p-description-showall':showAllDescription }"
                #pDescription>{{product.description[0].txt}}</p>
              <a class="see-more" (click)='showAllDescription = !showAllDescription'>
                <ng-container *ngIf="descriptionToLong">
                  <ng-container *ngIf="!showAllDescription">Voir plus</ng-container>
                  <ng-container *ngIf="showAllDescription">Réduire</ng-container>
                </ng-container>
              </a>
            </div>

            <p class="pListeDepart" [ngStyle]="{'display': showAllDescription ? 'none' : 'block'}">
              <i class="fas fa-plane-departure"></i>
              <ng-container *ngFor="let from of product.froms;  let i = index">
                {{from.label}}
                <ng-container *ngIf="i+1 !== product.froms.length">, </ng-container>
              </ng-container>
            </p>

            <div class="link-description" [ngStyle]="{'display': showAllDescription ? 'none' : 'inline-grid'}">
              <a (click)="goDescription()">
                <i class=" material-icons"> description</i>
                Description</a>
              <a (click)="infosRequest=true;">
                <i class="material-icons"> live_help </i>
                Demande d'informations
              </a>
            </div>

          </div>

          <div class="reservation card">

            <div *ngIf="!realBestPrice">
              <p>
                dès <span class="title-product span-price">{{product.prices.best[0].amount[0]}} €</span>
              </p>

              <p class="infos">
                {{bestprice.duration.day}} jours
                <ng-container *ngIf="product.duration.night > 0 "> / {{bestprice.duration.night}} nuits </ng-container>
                <br>
                {{bestprice.from.label}}<br>
                Le {{bestprice.date | convertdate}}
              </p>

              <a href="{{getLink()}}" target="_blank">
                <button class="btn-vendi btn-devis">DEVIS / RESERVATION </button>
              </a>
            </div>

            <div *ngIf="realBestPrice">
              <p>
                dès <span class="title-product span-price">{{realBestPrice.amount}} €</span>
              </p>

              <p class="infos">
                {{realBestPrice.duration.day}} jours
                <ng-container *ngIf="product.duration.night > 0 "> / {{realBestPrice.duration.night}} nuits
                </ng-container>
                <br>
                {{realBestPrice.from.label ? realBestPrice.from.label : ''}}<br>
                Le {{realBestPrice.date | convertdate}}
              </p>

              <a href="{{getLink(realBestPrice)}}" target="_blank">
                <button class="btn-vendi btn-devis">DEVIS / RESERVATION </button>
              </a>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="second-container" *ngIf="product&&!infosRequest">

      <div class="calendar" *ngIf="!disableCalendar">

        <form [formGroup]="formCalendar">
          <div class="container-select-calendar">
            <div class="txtcenter">
              <!-- {{tmpCitySelect}} -->
              <mat-form-field appearance="fill">
                <mat-label>VILLE DE DÉPART</mat-label>
                <mat-select [formControl]="villeDeDepart" (selectionChange)="changeTransport($event)"
                  [(ngModel)]="tmpCitySelect">
                  <mat-option *ngFor="let transport of product.froms" [value]="transport.iata">
                    {{transport.label}} <!-- - {{transport.iata}} -->
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="txtcenter">
              <mat-form-field appearance="fill">
                <mat-label>DATE DE DÉPART</mat-label>
                <mat-select [formControl]="dateDeDepart" (selectionChange)="changeMonth($event)"
                  [(ngModel)]='tmpDateForSelect'>
                  <!-- <mat-option [value]='false'>-- Indifférent --</mat-option> -->
                  <mat-optgroup *ngFor="let year of listMonths" [label]="'Année ' + year.year">
                    <mat-option *ngFor="let month of year.month" [value]="year.year+'-'+ month">
                      {{months[month]}} - {{year.year}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>

        <mwl-demo-utils-calendar-header [(view)]="view" [(viewDate)]="viewDate" [lastDate]="lastDate"
          [firstDate]="firstDate" (refreshdate)='refreshdate()'>
        </mwl-demo-utils-calendar-header>

        <div class="alert alert-info">
          <!-- TODO -->
        </div>

        <ng-template #customCellTemplate let-day="day" let-locale="locale">
          <div class="cal-cell-top" (click)="consoleDayEvents(day.events)">
            <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
          </div>
          <div class="dayevent-container" *ngIf="day.events.length>0">
            <span class="dayevent-promo" *ngIf="day.events[0].title.split('&&')[1]==='true'">
              Promo <br></span>
            <div class="dayevent-price"> <span style="font-size: 12pt; font-weight: lighter;"></span>
              {{ day.events[0].title.split('&&')[0] }}€</div>
            <button class="btn-vendi seedevis">Voir le devis</button>
          </div>
        </ng-template>

        <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [cellTemplate]="customCellTemplate"
          class="calendar-body" [locale]="locale" [weekStartsOn]="weekStartsOn" [weekendDays]="weekendDays">
        </mwl-calendar-month-view>


      </div>

      <div class="long-description" #longDescription>
        <span class="title-description"> <i class="material-icons"> description</i>
          Description</span>
        <br>
        <ng-container *ngFor="let t of arrayDescription;">
          <span *ngIf="product.description[t].title" class="chapter-title spanTitleProduct"
            [innerHTML]='product.description[t].title'></span>
          <p [innerHTML]='product.description[t].txt'> </p>
        </ng-container>
      </div>

    </div>

    <div class="second-container informations-request" *ngIf="infosRequest">
      <a (click)="infosRequest=false;" style="display: flex; font-size: 14pt;">
        <i style="font-size: 18pt; color: rgb(30, 30, 30); padding-right: 10px;" class="fas fa-arrow-left"></i>
        Retour
      </a>

      <div class="infosrequest">
        <form [formGroup]="formInfos">

          <h3>COORDONNÉES</h3>
          <div class="part-form-infosrequest">

            <div>
              <label>Civilité *</label>
              <mat-select name='gender' [(ngModel)]="infosRequestObject.gender" class="form-control"
                formControlName="gender">
                <mat-option value="Mr">Mr</mat-option>
                <mat-option value="Mme">Mme</mat-option>
              </mat-select>
              <span class="invalidField" *ngIf='!formInfos.get("gender").valid&&formInfosIsSubmitted&&!formInfos.valid'>
                Champs requis
              </span>
            </div>

            <div>
              <label>Nom *</label>
              <input [(ngModel)]="infosRequestObject.lastname" class="form-control" formControlName="lastname">
              <span class="invalidField"
                *ngIf='!formInfos.get("lastname").valid&&formInfosIsSubmitted&&!formInfos.valid'>
                Champs requis
              </span>

            </div>


            <div>
              <label>Prénom *</label>
              <input [(ngModel)]="infosRequestObject.firstname" formControlName="firstname" class="form-control">
              <span class="invalidField"
                *ngIf='!formInfos.get("firstname").valid&&formInfosIsSubmitted&&!formInfos.valid'>
                Champs requis
              </span>
            </div>


            <div>
              <label>Adresse</label>
              <input [(ngModel)]="infosRequestObject.address" formControlName="address" class="form-control">
            </div>


            <div>
              <label>Code postal</label>
              <input [(ngModel)]="infosRequestObject.postalcode" formControlName="postalcode" class="form-control">
            </div>


            <div>
              <label>Ville</label>
              <input [(ngModel)]="infosRequestObject.city" formControlName="city" class="form-control">
            </div>


            <div>
              <label>Pays</label>
              <input [(ngModel)]="infosRequestObject.country" formControlName="country" class="form-control">
            </div>


            <div>
              <label>Email *</label>
              <input [(ngModel)]="infosRequestObject.email" formControlName="email" class="form-control">
              <span class="invalidField" *ngIf='!formInfos.get("email").valid&&formInfosIsSubmitted&&!formInfos.valid'>
                Champs requis
              </span>
            </div>

            <div>
              <label>Téléphone *</label>
              <input [(ngModel)]="infosRequestObject.tel" formControlName="tel" class="form-control">
              <span class="invalidField" *ngIf='!formInfos.get("tel").valid&&formInfosIsSubmitted&&!formInfos.valid'>
                Champs requis
              </span>
            </div>

          </div>

          <div class="champsobligatoires">* Champs obligatoires
          </div>

          <h3>LES PARTICIPANTS</h3>

          <div class="part-form-infosrequest">

            <div>
              <label>Nombre de participants Adultes (plus de 12 ans)</label>
              <input [(ngModel)]="infosRequestObject.participants.adults" formControlName="adults" class="form-control">
            </div>


            <div>
              <label>Nombre de participants Enfants (de 2 à 12 ans)</label>
              <input [(ngModel)]="infosRequestObject.participants.children" formControlName="children"
                class="form-control">
            </div>


            <div>
              <label>Nombre de participants Bébés (moins de 2 ans)</label>
              <input [(ngModel)]="infosRequestObject.participants.babies" formControlName="babies" class="form-control">
            </div>

          </div>

          <h3>VOS PRÉCISIONS</h3>
          <span>Si vous avez une demande particulière n'hésitez pas à nous en faire part ci-dessous</span>

          <textarea [(ngModel)]="infosRequestObject.details" formControlName="details" class="txtarea" type="text"
            id="form8" mdbInput maxlength="5000"
            (ngModelChange)='txtLimite(infosRequestObject.details.length)'></textarea>

          <mat-checkbox [(ngModel)]="cgvAccepted" [ngModelOptions]="{standalone: true}">
            En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le
            cadre d'une demande d'information ou de demande de devis et de la relation commerciale qui peut
            en découler.
            Pour connaître et exercer vos droits en matière de Protection des Données, notamment de retrait
            de votre consentement à l'utilisation des données collectées par ce formulaire, veuillez
            consulter les mentions légales du site ou remplir le formulaire de contact.
          </mat-checkbox>

          <span class="invalidField" *ngIf='formInfosIsSubmitted&&!cgvAccepted'>
            Champs requis
          </span>

          <!-- CAPTCHA -->
          <div class="recaptcha-container">

            <form [formGroup]="reactiveForm">
              <re-captcha formControlName="recaptchaReactive"></re-captcha>
            </form>

            <span class="invalidField" *ngIf='formInfosIsSubmitted && this.reactiveForm.invalid'>
              Champs requis
            </span>

          </div>

          <div style="text-align: center; margin: 40px 0;">
            <input type="submit" (click)="submitForm()" class="btn-vendi">

          </div>


        </form>
      </div>


    </div>

    <!-- </ng-container> -->
  </app-loading>

</section>
