import { NgZone } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "@angular/common/http";
export class HelperService {
    constructor(toast, _snackBar, zone, http) {
        this.toast = toast;
        this._snackBar = _snackBar;
        this.zone = zone;
        this.http = http;
    }
    message(msg, type) {
        console.log(msg, type);
        if (type === 'success')
            this.toast.success(msg);
        if (type === 'error')
            this.toast.error(msg);
        if (type === 'info')
            this.toast.info(msg);
    }
    openSnackBar(message, action = '') {
        let config = new MatSnackBarConfig();
        config.verticalPosition = 'bottom';
        config.horizontalPosition = 'center';
        config.duration = 1500;
        this.zone.run(() => {
            this._snackBar.open(message, action, config);
        });
    }
    scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    txtLimite(length, limite) {
        console.log(length, limite);
        if (length >= limite)
            this.message('error', "Limite de caractères atteinte");
    }
    onkeyOnlyNumber(value) {
        const char = value.key;
        const numberList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'];
        console.log(value);
        console.log(numberList.includes(char));
        return numberList.includes(char);
    }
}
HelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.MatSnackBar), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i3.HttpClient)); }, token: HelperService, providedIn: "root" });
