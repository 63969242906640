<div class="search">
  <div class="containerSearch" [ngClass]="{'vertical-container-search' : !horizontal }">
    <div class="containerInput">

      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">
        <app-multiselect [ngClass]="{'multiselect-for-firefox': browser === 'Firefox'}" class='multiselect'
          [data]="dataDestination"></app-multiselect>
      </div>

      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">
        <app-multiselect [ngClass]="{'multiselect-for-firefox': browser === 'Firefox'}" class='multiselect'
          [data]="dataDepart"></app-multiselect>
      </div>

      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">
        <mat-form-field class="standardinput-h">
          <mat-label>Date de départ</mat-label>
          <input (click)="picker.open()" (dateInput)="addToParams('date_depart',$event)" [readonly]=" true" matInput
            [min]="today" [matDatepicker]="picker" [(ngModel)]="tmpParams.date_depart">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker (selectedChanged)="addToParams('date_depart',$event)"></mat-datepicker>

          <i *ngIf="tmpParams.date_depart"
            (click)="tmpParams.date_depart = ''; removeFromParams('date_depart', '', false)"
            class="fas fa-times-circle ico-reset-date"></i>

        </mat-form-field>
      </div>


      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">

        <mat-form-field class="standardinput-h" *ngIf="!dureePersonalise">
          <mat-select name='duree' placeholder="Durée" [(ngModel)]='params.nuits[0]' #durationSelect>
            <mat-option value=""> -- Indifférent -- </mat-option>
            <mat-option (click)="dureePersonalise = true"> Personalisé </mat-option>
            <mat-option value="<2"> Court séjour ou weekend </mat-option>
            <mat-option value="7"> 1 semaine </mat-option>
            <mat-option value="14"> 2 semaines </mat-option>
            <mat-option value=">14"> + 2 semaines </mat-option>
            <!-- <mat-option value=""> Personalisée </mat-option> -->
            <!-- <mat-option *ngIf="durationSelect.value==1" value="1">
                            <input matInput type="text">
                        </mat-option> -->

          </mat-select>
          <i class="fas fa-history" style="transform: rotateY(190deg);"></i>
        </mat-form-field>

        <mat-form-field class="standardinput-h mat-form-field-perso" *ngIf="dureePersonalise">
          <input matInput name='dureePersonalise' autofocus [(ngModel)]="params.nuits[0]" class="text-align-center"
            (keydown)="onkeyOnlyNumber($event)">
          <span matSuffix>jours</span>
        </mat-form-field>
        <div class="close-input-perso" (click)="dureePersonalise = false; params.nuits[0]=''" *ngIf="dureePersonalise">
          <i class="fas fa-times"></i>
        </div>

      </div>

      <!-- <div class="divInput" *ngIf="durationSelect.value==1">
                <input matInput type="text">
            </div> -->

      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">

        <mat-form-field class="standardinput-h" *ngIf="!flexibilitePersonalise">
          <mat-select name='duree' placeholder="Flexibilité" [(ngModel)]='params.souplesse[0]'>
            <mat-option value=""> -- Indifférent -- </mat-option>
            <mat-option (click)="flexibilitePersonalise = true"> Personalisé </mat-option>
            <mat-option value="3"> +/- 3 jours </mat-option>
            <mat-option value="7"> +/- 7 jours </mat-option>
            <mat-option value="15"> +/- 15 jours </mat-option>
            <mat-option value=">30"> +/- 30 jours </mat-option>
            <!-- <mat-option value=""> Personalisée </mat-option> -->
          </mat-select>
        </mat-form-field>

        <mat-form-field class="standardinput-h mat-form-field-perso" *ngIf="flexibilitePersonalise">
          <!-- <div *ngIf="flexibilitePersonalise" class="container-input-flexibilite-personnlise"> -->
          <input matInput name='flexibilitePersonalise' autofocus [(ngModel)]="params.souplesse[0]"
            class="text-align-center" (keydown)="onkeyOnlyNumber($event)"> <!-- type="number">  -->
          <span matPrefix>+/-</span>
          <span matSuffix>jours</span>
        </mat-form-field>
        <div class="close-input-perso" (click)="flexibilitePersonalise = false; params.souplesse[0]=''"
          *ngIf="flexibilitePersonalise">
          <i class="fas fa-times"></i>
        </div>

      </div>

      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">
        <mat-form-field class="standardinput-h">
          <mat-select name='theme' placeholder="Thématique" [(ngModel)]='params.type[0]'>
            <mat-option value=""> -- Indifférent -- </mat-option>
            <mat-option *ngFor="let theme of thematiques" value="{{theme.id}}">{{theme.libelle}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">
        <mat-form-field class="standardinput-h">
          <mat-select name='regime' placeholder="Formule" [(ngModel)]='params.regime[0]'>
            <mat-option value=""> -- Indifférent -- </mat-option>
            <mat-option *ngFor="let regime of regimes" value="{{regime.code}}"> {{regime.libelle}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="divInput" [ngClass]="{'vertical-div-input' : !horizontal }">
        <mat-form-field class="standardinput-h">
          <input matInput name='motcle' placeholder="Mot clé" [(ngModel)]="params.motcle[0]">
          <i class="material-icons"> vpn_key </i>
        </mat-form-field>
      </div>

      <!--
            <mat-form-field class="standardinput-h">
                <mat-checkbox [(ngModel)]="" >Voir uniquement les offres en promotions</mat-checkbox>
            </mat-form-field> 
            -->

      <div class="divInput divBudget" [ngClass]="{'vertical-div-budget' : !horizontal }">
        <div class="containerBudget">
          <mat-option class="ngSlider" style="height: 4em; line-height: 1em; padding: 0 20px;">
            <ng5-slider [(value)]="this.tmpParams.minBudget" [(highValue)]="this.tmpParams.maxBudget"
              [options]="options" (userChangeEnd)="changeBudget($event)">
            </ng5-slider>
          </mat-option>
        </div>
      </div>

    </div>

    <div class="container-button" [ngClass]="{'vertical-container-button' : !horizontal }">
      <button (click)="goSearch()" class="btn-vendi">Rechercher</button>

    </div>
  </div>

</div>
