import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
const _ = require('lodash');
// import listeDestinations from '../ressources/listeDestinations.json';
// import airports from '../ressources/airports.json'
// import thematiques from '../ressources/thematiques.json'
export class HomeComponent {
    constructor(vt, loadingService, router) {
        this.vt = vt;
        this.loadingService = loadingService;
        this.router = router;
        this.allOffers = {
            left: [],
            right: []
        };
        this.imgurl = `${environment.apiUrl}/uploads/`;
        this.partnersLoto = [
            'atoutfrance.jpg',
            'iata.png',
            'atradius.png',
            'fiskagroup.png',
            'leclubby.png'
        ];
        this.homeFooter = [
            { title: '600 compagnies', description: 'Nous interrogeons plus de 600 compagnies', img: 'company.png' },
            { title: "Besoin d'aide ?", description: 'Nos experts sont à votre disposition', img: 'help.png' },
            { title: 'Des prix négociés', description: 'Des tarifs négociés spécialement pour vous', img: 'euro.png' },
            { title: 'Paiement sécurisé', description: 'Réservation simple et paiement en ligne 100% sécurisé', img: 'padlock.png' }
        ];
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loadingService.startWithChannel('bestoffers');
            let tmpOffersList = yield this.vt.getOffers().toPromise();
            this.allOffers = this.prepareOffers(tmpOffersList);
            console.log(this.allOffers);
            this.loadingService.stopWithChannel('bestoffers');
        });
    }
    consoletest() {
        console.log(this.vt.params);
    }
    goSearch() {
        this.router.navigate(['/search']);
    }
    prepareOffers(response) {
        console.log(response);
        response = response.map((o) => {
            if (o.img.local) {
                o.img.link = {
                    background: `url(${this.imgurl}${o.img.link})`
                };
            }
            else {
                o.img.link = {
                    background: `url(${o.img.link})`
                };
            }
            return o;
        });
        const allOffers = {
            left: response.slice(0, 3),
            right: response.slice(3, 6)
        };
        return allOffers;
    }
    goToOffer(id) {
        console.log(id);
        this.router.navigate([`/product/${id}`]);
    }
}
