import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { VendiTravelsService } from 'src/app/services/venditravels.service';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { Options } from 'ng5-slider';
import { MultiselectComponent } from '../multiselect/multiselect.component';
import { Subscription } from 'rxjs';
import { MAT_DATE_FORMATS } from '@angular/material';

const _ = require('lodash');

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.scss'],

})

export class SearchComponentComponent implements OnInit, OnDestroy {
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @Input() horizontal: Boolean;

  public listeDestinations = [];
  public airports = [];
  public thematiques = [];
  public tmpValue: any;
  public today: Date = new Date();
  public resultSearch;
  public lodash = _;
  public searchActive: Boolean = false;
  public tmpRegime: String = '';
  public params: any = [];
  public regimes = [];
  public options: Options = {
    floor: 0,
    ceil: 5000,
    step: 100,
    minRange: 100,
    //showTicks: true,
    translate: (value: number): string => {
      let tmp = (value < 1000 ? value : value.toString().slice(0, 1) + ' ' + value.toString().slice(-3)) + '€';
      if (value === 5000) tmp = '>' + tmp;
      return tmp;
    },
  };
  public browser = '';

  public dataDestination = {
    list: this.vt.listeDestinations,
    todisplay: 'pays',
    topush: 'code',
    type: 'destination',
    placeholder: ' Destination'
  };

  public dataDepart = {
    list: this.vt.airports,
    todisplay: 'city',
    topush: 'IATA',
    type: 'depart',
    placeholder: ' Ville de départ'
  };

  public tmpParams = { minBudget: 0, maxBudget: 5000, date_depart: '' };

  public flexibilitePersonalise: Boolean = false;
  public dureePersonalise: Boolean = false;

  private _resetSearchSubscription: Subscription;


  constructor(
    private vt: VendiTravelsService,
    private loadingService: LoadingService,
    private router: Router,
    private hlp: HelperService,
  ) { }


  ngOnInit() {

    this.browser = this.vt.browser;
    console.log(this.browser)

    this._resetSearchSubscription = this.vt.subscribeResetSearch((s) => {
      this.params = this.vt.params;
      this.tmpParams = this.vt.tmpParams;
      this.goSearch()

    })
    this.params = this.vt.params;
    this.regimes = this.vt.regimes;
    this.thematiques = this.vt.thematiques;
    this.tmpParams = this.vt.tmpParams;

  }

  ngOnDestroy() {
    this._resetSearchSubscription.unsubscribe()
  }

  addToParams(key, value, multipleChoice = true) {
    this.params = this.vt.addToParams(key, value, multipleChoice);
  }

  removeFromParams(key, value, multipleChoice = true) {
    // console.log(key, value, multipleChoice)
    this.vt.removeFromParams(key, value, multipleChoice);
  }

  changeBudget(event) {
    // console.log(event);
    this.vt.tmpParams.minBudget = event.value;
    this.vt.tmpParams.maxBudget = event.highValue
    this.tmpParams = this.vt.tmpParams

    if (event.value === 0 && event.highValue === 5000) {
      this.removeFromParams('budget', '', false)
    }
    else if (event.value === 0 && event.highValue < 5000) {
      this.vt.addToParams('budget', `<${event.highValue}`, false)
    }
    else if (event.value > 0 && event.highValue === 5000) {
      this.vt.addToParams('budget', `>${event.value}`, false)
    }
    else if (event.value > 0 && event.highValue < 5000) {
      this.vt.addToParams('budget', `${event.value}-${event.highValue}`, false)
    }


  }

  async goSearch() {
    this.search.emit()
  }

  onkeyOnlyNumber(value) {
    return this.hlp.onkeyOnlyNumber(value);
  }



}
