<div class="header">
    <div class="top-header">
        <div class="div-logo" (click)="goHome()">
            <img src="assets/venditravels-logo.png">
        </div>
        <div class="div-menu" (click)="activeSideNavMenu()">
            <i class="fas fa-bars"></i>
        </div>
        <div class="contact-infos">
            <a href="tel:+33387885670">03 87 88 09 30<i class="fas fa-phone-alt"></i></a><br>
            <a href="mailto:info@venditravels.com">info@venditravels.com<i class="fas fa-envelope"></i></a>
        </div>
    </div>
    <div class="bottom-header">
        <div class="div-listonglet">
            <ul>
                <li routerLink='/'>ACCUEIL</li> <span class="bar"></span>
                <li><a href="https://venditravels-vol.resatravel.com/?">VOLS</a></li> <span class="bar"></span>
                <li (click)="changePage(1)">SÉJOURS</li> <span class="bar"></span>
                <li (click)="changePage(2)">CIRCUITS</li> <span class="bar"></span>
                <li (click)="changePage(21)">LOCATIONS</li> <span class="bar"></span>
                <li (click)="changePage(5)">CROISIÈRES</li> <span class="bar"></span>
                <li (click)="changePage(3)">WEEK-END</li>
            </ul>
        </div>
    </div>

</div>