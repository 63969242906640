import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'convertdate' })
export class convertDate implements PipeTransform {
    constructor() { }

    transform(date) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        let d = new Date(date)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

}
