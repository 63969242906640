/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular-calendar";
import * as i3 from "@angular/common";
import * as i4 from "./calendar-header.components";
var styles_CalendarHeaderComponent = [i0.styles];
var RenderType_CalendarHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarHeaderComponent, data: {} });
export { RenderType_CalendarHeaderComponent as RenderType_CalendarHeaderComponent };
function View_CalendarHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "btn btn-previous"], ["mwlCalendarPreviousView", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        _co.viewDateChange.next(_co.viewDate);
        var pd_2 = (_co.refreshDate() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵf, [i2.DateAdapter], { view: [0, "view"], viewDate: [1, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-chevron-left"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; var currVal_1 = _co.viewDate; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CalendarHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "btn btn-next"], ["mwlCalendarNextView", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        _co.viewDateChange.next(_co.viewDate);
        var pd_2 = (_co.refreshDate() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵg, [i2.DateAdapter], { view: [0, "view"], viewDate: [1, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; var currVal_1 = _co.viewDate; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CalendarHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵi, [i2.CalendarDateFormatter, i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarHeaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["class", "disabledClick"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", "", ""])), i1.ɵppd(9, 3), i1.ɵppd(10, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarHeaderComponent_2)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.viewDate.getMonth() > _co.firstDate.getMonth()) || (_co.viewDate.getFullYear() > _co.firstDate.getFullYear())); _ck(_v, 5, 0, currVal_0); var currVal_3 = _co.maxDate(); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.viewDate, (_co.view + "ViewTitle"), _co.locale)).charAt(0).toUpperCase(); var currVal_2 = i1.ɵunv(_v, 8, 1, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.viewDate, (_co.view + "ViewTitle"), _co.locale)).slice(1); _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_CalendarHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mwl-demo-utils-calendar-header", [], null, null, null, View_CalendarHeaderComponent_0, RenderType_CalendarHeaderComponent)), i1.ɵdid(1, 573440, null, 0, i4.CalendarHeaderComponent, [], null, null)], null, null); }
var CalendarHeaderComponentNgFactory = i1.ɵccf("mwl-demo-utils-calendar-header", i4.CalendarHeaderComponent, View_CalendarHeaderComponent_Host_0, { view: "view", viewDate: "viewDate", firstDate: "firstDate", lastDate: "lastDate", locale: "locale" }, { viewChange: "viewChange", viewDateChange: "viewDateChange", refreshdate: "refreshdate" }, []);
export { CalendarHeaderComponentNgFactory as CalendarHeaderComponentNgFactory };
