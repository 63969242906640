import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, MentionsLegales, CGV } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './pages/home/home.component';
import { SearchComponent } from './pages/search/search.component';
import { SearchComponentComponent } from './global-components/search/search-component.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatStepperModule, MatDatepickerModule, MatDialogModule, MatIconModule, MatInputModule, MatNativeDateModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatTableModule } from '@angular/material';
import { Ng5SliderModule } from 'ng5-slider';
import { LoadingService } from './services/loading.service';
import { MultiselectComponent } from './global-components/multiselect/multiselect.component';
import { AppLoadingComponent } from './shared/utils/loading/loading.component';
import { HeaderComponent } from './global-components/header/header.component';
import { FooterComponent } from './global-components/footer/footer.component';
import { roundedRating } from './shared/utils/roudedRating.pipe'
import { SafeHtmlPipe } from './shared/utils/safe-html.pipe'
import { SafePipe } from './shared/utils/safe.pipe'
import { TimesPipe } from './shared/utils/times.pipe';
import { ProductComponent } from './pages/product/product.component';
import { convertDate } from './shared/utils/convertDate.pipe';
// import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarHeaderComponent, /* CustomDateFormatter */ } from './pages/product/calendar-header/calendar-header.components';
import localeFr from '@angular/common/locales/fr';
import { HelperService } from './services/helper.service';
import { ToastrModule, OverlayContainer, ToastrService, Overlay, ToastContainerModule } from 'ngx-toastr';
import { DeviceDetectorModule } from 'ngx-device-detector';
// import { DateAdapter as ngDateAdatpter } from "@angular/material";
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter as MatDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchComponent,
    SearchComponentComponent,
    AppLoadingComponent,
    MultiselectComponent,
    HeaderComponent,
    FooterComponent,
    roundedRating,
    SafeHtmlPipe,
    SafePipe,
    TimesPipe,
    convertDate,
    ProductComponent,
    CalendarHeaderComponent,
    MentionsLegales,
    CGV,
    // CustomDateFormatter
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DeviceDetectorModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    FontAwesomeModule,

    /* material */
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ToastrModule.forRoot(), // ToastrModule added
    ToastContainerModule,
    // MDBBootstrapModule,
    SlickCarouselModule,


    RecaptchaModule,
    RecaptchaFormsModule,

  ],
  exports: [CalendarHeaderComponent]
  ,
  providers: [
    HttpClientModule,
    LoadingService,
    ToastrService,
    Overlay, OverlayContainer,
    // { provide: ngDateAdatpter, useClass: DateFormat }
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LdWeOMZAAAAAPU1b563ww0dvt15pVyreEpoTMuR', } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }


