import {Subscription}   from "rxjs";
import {Observable}     from "rxjs";
import {Observer}       from 'rxjs';

import { share } from 'rxjs/operators';

export class EventBus<T> {

  private _observer   : Observer<T>;
  private _observable : Observable<T>;

  constructor(){
    this._observable = new Observable<T>(observer => this._observer = observer).pipe(share());
    let tempSub = this.subscribe(()=> {});
    tempSub.unsubscribe();

  }

  public publish(value : T){
    this._observer.next(value);
  }

  public subscribe( callback : (T) => void): Subscription {
    return this._observable.subscribe(callback);
  }
}
