import { Injectable, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class HelperService {


  constructor(
    private toast: ToastrService,
    private _snackBar: MatSnackBar,
    private zone: NgZone,
    private http: HttpClient,
  ) { }

  message(msg, type) {
    console.log(msg, type)
    if (type === 'success') this.toast.success(msg)
    if (type === 'error') this.toast.error(msg)
    if (type === 'info') this.toast.info(msg)
  }


  openSnackBar(message, action = '') {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 1500

    this.zone.run(() => {
      this._snackBar.open(message, action, config);
    });
  }


  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }



  txtLimite(length, limite) {
    console.log(length, limite)
    if (length >= limite) this.message('error', "Limite de caractères atteinte")
  }


  onkeyOnlyNumber(value) {

    const char = value.key;
    const numberList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'];

    console.log(value)
    console.log(numberList.includes(char));

    return numberList.includes(char);
    
  }

}
