/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./header.component";
import * as i4 from "../../services/venditravels.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 38, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "top-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "div-logo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/venditravels-logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "div-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeSideNavMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-bars"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "contact-infos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["href", "tel:+33387885670"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["03 87 88 09 30"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fas fa-phone-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["href", "mailto:info@venditravels.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["info@venditravels.com"])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fas fa-envelope"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 24, "div", [["class", "bottom-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 23, "div", [["class", "div-listonglet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 22, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "li", [["routerLink", "/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["ACCUEIL"])), (_l()(), i1.ɵeld(20, 0, null, null, 0, "span", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "a", [["href", "https://venditravels-vol.resatravel.com/?"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["VOLS"])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "span", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["S\u00C9JOURS"])), (_l()(), i1.ɵeld(27, 0, null, null, 0, "span", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CIRCUITS"])), (_l()(), i1.ɵeld(30, 0, null, null, 0, "span", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage(21) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["LOCATIONS"])), (_l()(), i1.ɵeld(33, 0, null, null, 0, "span", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage(5) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CROISI\u00C8RES"])), (_l()(), i1.ɵeld(36, 0, null, null, 0, "span", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["WEEK-END"]))], function (_ck, _v) { var currVal_0 = "/"; _ck(_v, 18, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.VendiTravelsService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i3.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
