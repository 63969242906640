<mat-form-field class="customMatFormField_VENDI">
  <mat-chip-list id="chipList" #chipList aria-label="selection" class="matchips"
      (click)="input.click(); input.focus()">
      <div class="chips-container" #chipscontainer>
          <mat-chip *ngFor="let selected of selectedlist" [removable]="true"
              (removed)="remove(selected)">
              {{selected[data.todisplay]}}
              <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
      </div>
      <input placeholder="{{data.placeholder}}" #input [formControl]="ctrl" [matAutocomplete]="auto"
          [matChipInputFor]="chipList" (click)="openPanel($event); scrollDownList()" #inputTrigger id="input">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let element of filteredlist | async" (click)="$event.stopPropagation();scrollDownList()">
          {{element[data.todisplay]}}
      </mat-option>
  </mat-autocomplete>
</mat-form-field>